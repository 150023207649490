import ReactModal from "react-modal";
import CancelBtnImg from "../../assets/cancelBtn.png";
import AddressSelect from "../../components/address/AddressSelect";
import styled from "styled-components";
import React, {useCallback, useEffect, useState} from "react";
import {gray500} from "../../styles/fontColors";
import EssentialSignImg from "../../assets/essentialSign.png";
import intl from "react-intl-universal";

const ChangeShippingAddressModal = (props) => {
  const [shippingAddress, setShippingAddress] = useState({});

  useEffect(() => {
    if (props.order?.shippingAddress) {
      setShippingAddress(JSON.parse(JSON.stringify(props.order.shippingAddress)));
    }
  }, [props.order]);

  const onChangeRecipientInfo = useCallback((key, e) => {
    const shippingAddressState = {...shippingAddress};

    shippingAddressState[key] = e.target.value;
    setShippingAddress(shippingAddressState);
  }, [shippingAddress]);

  const onOk = (provinceName, cityName, countyName, county, detail) => {
    const countryCode = county === "990101" ? "82" : "86";
    const shippingAddressState = {...shippingAddress};
    shippingAddressState.addressProvince = provinceName;
    shippingAddressState.addressCity = cityName;
    shippingAddressState.addressDistrict = countyName;
    shippingAddressState.addressCode = county;
    shippingAddressState.addressDetail = detail;
    shippingAddressState.countryCode = countryCode;
    setShippingAddress(shippingAddressState);

    props.onOk(shippingAddressState);
  }

  return (
    <ReactModal
      isOpen={true}
      onRequestClose={props.onCancel}
      shouldCloseOnOverlayClick={true}
      style={{
        overlay: {position: "fixed", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: gray500},
        content: {
          position: "absolute",
          width: "820px",
          height: "530px",
          margin: "auto",
          border: "1px solid #ccc",
          background: "#fff",
          overflow: "none",
          WebkitOverflowScrolling: "touch",
          borderRadius: "8px",
          outline: "none",
          padding: "29px",
          boxSizing: "border-box",
        },
      }}
    >
      <CancelBtn
        src={CancelBtnImg}
        alt="cancelBtn"
        onClick={props.onCancel}
      />
      <Container>
        <Title>{intl.get("order.change.shippingAddress")}</Title>
        <ul>
          <Content>
            <img className="essentialSign" src={EssentialSignImg} alt="essentialSign" />
            <p>{intl.get("order.recipient")}</p>
            <input value={shippingAddress.recipient || ""} onChange={(e) => { onChangeRecipientInfo("recipient", e); }} />
          </Content>
          <Content>
            <img className="essentialSign" src={EssentialSignImg} alt="essentialSign" />
            <p>{intl.get("order.phone")}</p>
            <input value={shippingAddress.phone || ""} onChange={(e) => { onChangeRecipientInfo("phone", e); }} />
          </Content>
          <Content>
            <p>{intl.get("order.userComment")}</p>
            <input value={shippingAddress.userComments || ""} onChange={(e) => { onChangeRecipientInfo("userComments", e); }} />
          </Content>
        </ul>
        <AddressSelect
          addressCode={shippingAddress.addressCode}
          addressDetail={shippingAddress.addressDetail}
          disableSelection={props.order?.orderType === "wholesale"}
          hideTitle={true}
          onCancel={props.onCancel}
          onOk={onOk}
        />
      </Container>
    </ReactModal>
  );
};

const Title = styled.div`
 font-size: 24px;
 font-weight: 700;
 width: 100%;
 text-align: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Content = styled.li`
  display: flex;
  max-height: 44px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 20px;

  div {
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 820px;
  }

  p {
    font-size: 16px;
    font-weight: 700;
    margin-left: 8px;
    margin: 0 0 0 8px;
  }

  .addressInput {
    width: 652px;
  }

  input {
    width: 650px;
    height: 44px;
    box-sizing: border-box;
    font-size: 20px;
    border: 1px solid #e0e0e0;
    padding: 10px 16px;
  }

  button {
    height: 44px;
    width: 148px;
    background-color: ${gray500};
    border-radius: 2px;
    border: none;
    font-size: 16px;
    font-weight: 400;
    color: white;
    cursor: pointer;
  }

  .essentialSign {
    position: absolute;
    width: 6px;
    top: 15px;
    left: 0px;
  }
`;

const CancelBtn = styled.img`
  position: absolute;
  right: 29px;
  top: 29px;
  height: 14px;
  width: 14px;
  cursor: pointer;
`;

export default ChangeShippingAddressModal;