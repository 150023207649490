import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import intl from "react-intl-universal";
import { useHistory } from "react-router-dom";
import { Alert } from 'antd';

import SelectArrow from "../../assets/selectArrow.png";
import ArrowTop from "../../assets/arrow-top.png";

import ProductSkeleton from "./ProductSkeleton";
import ProductApi from "../../components/apis/ProductApi";
import PriceList from "../../components/priceList/PriceList";
import AppInfoContext from "../../contexts/AppInfoContext";
import { gray100, gray300, gray500, mainColor2 } from "../../styles/fontColors";
import SkuPrice from "../../components/priceList/SkuPrice";
import SkuTotalPrice from "../../components/priceList/SkuTotalPrice";
import Util from "../../utils/Util";
import {EventBroadcaster, MEMBER_CONTEXT_AVAILABLE_EVENT_TOPIC} from "../../event/event.broadcaster";

function ProductDetail(props) {
  const { appInfo, detailPageSettings } = useContext(AppInfoContext);

  const productId = props.match.params.productId;
  const history = useHistory();

  const [product, setProduct] = useState({
    skus: [],
  });
  const [selectClicked, setSelectClicked] = useState(false);
  const [selectedSkus, setSelectedSkus] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    loadProduct();
  }, [productId]);

  useEffect( () => {
    // 브라우저 리로딩 등으로 인증 정보가 없어진 경우
    EventBroadcaster.on(MEMBER_CONTEXT_AVAILABLE_EVENT_TOPIC, (memberInformation) => {
      loadProduct();
    });
  }, []);

  useEffect(() => {
    setTotalPrice(
      Number(
        selectedSkus?.reduce((acc, cur) => {
          return cur.salesPrice.originPrice * cur.quantity + acc;
        }, 0)
      )
    );
  }, [selectedSkus]);

  const loadProduct = () => {
    ProductApi.getProduct(productId)
      .then((res) => {
        const product =  res.data;
        setProduct(res.data);
      }).catch((err) => {
      alert(intl.get("common.message.contactAdmin"));
    });
  };

  const onClickSelect = useCallback(() => {
    setSelectClicked((prev) => !prev);
  }, []);

  const createNewArr = useCallback(
    (index, sign) => {
      const plusSkusQuantity = {
        ...selectedSkus[index],
        quantity:
          sign === "minus"
            ? --selectedSkus[index].quantity
            : ++selectedSkus[index].quantity,
      };
      const newArr = [...selectedSkus];
      newArr[index] = plusSkusQuantity;
      setSelectedSkus(newArr);
    },
    [selectedSkus]
  );

  const onClickOption = useCallback(
    (select) => {
      const skusIndex = selectedSkus.findIndex((e) => e.id === select.id);
      if (skusIndex > -1) {
        createNewArr(skusIndex, "plus");
        return;
      }
      setSelectedSkus((prev) => [...prev, { ...select, quantity: 1 }]);
    },
    [selectedSkus, createNewArr]
  );

  const onclickPlus = useCallback(
    (index) => {
      // if (selectedSkus[index].quantity > 99) return;
      createNewArr(index, "plus");
    },
    [selectedSkus, createNewArr]
  );

  const onclickMinus = useCallback(
    (index) => {
      if (selectedSkus[index].quantity < 2) return;
      createNewArr(index, "minus");
    },
    [selectedSkus, createNewArr]
  );

  const onClickOptionCancel = useCallback(
    (index) => {
      const newArr = [...selectedSkus];
      newArr.splice(index, 1);
      setSelectedSkus(newArr);
    },
    [selectedSkus]
  );

  const skuQuantityChanged = useCallback((e, skuId) => {
    let value = e.target.value === "" || isNaN(e.target.value) ? "" : parseInt(e.target.value);

    const selectedSkusState = selectedSkus.map(sku => {
      if (sku.id === skuId) {
        sku.quantity = value;
      }
      return sku;
    })
    setSelectedSkus(selectedSkusState);
  }, [selectedSkus]);

  const onClickBuyButton = useCallback(() => {
    if (!selectedSkus[0]) {
      alert(
        intl.get("common.message.selectAtLeastOne", {
          name: intl.get("products.options"),
        })
      );
      return;
    }

    for (const sku of selectedSkus) {
      if (sku.quantity === 0 || sku.quantity === "") {
        alert(intl.get("common.inputField", {name: intl.get("order.quantity")}));
        return;
      }
    }
    if (!Util.isLoginUser()) {
      if (window.confirm(intl.get("common.message.request-login") + " " +
        intl.get("common.message.moveToLogin"))) {
        history.replace("/login");
      }
      return;
    }

    if (product.wholesaleProduct && product.wholesaleMinProducts > 0) {
      const sum = selectedSkus.reduce(function add(sum, currSku) {
        return sum + currSku.quantity;
      }, 0);
      if (sum < product.wholesaleMinProducts) {
        alert(intl.get("products.message.minNums", {count: product.wholesaleMinProducts}));
        return;
      }
    }

    history.push({
      pathname: "/prepare-order",
      state: {
        itemName: product.name,
        selectedSkus: selectedSkus,
      },
    });
  }, [history, selectedSkus, product]);

  const settingValues = detailPageSettings?.filter((setting) => setting.type === "info")[0].settingValues.attrs;

  return (
    <Container>
      {product.id ? (
        <>
          <ItemInfo>
            <ItemImage src={product.id && product?.images[0] || ""} />
            <ItemSummary>
              <div className="subject">{product?.name}</div>
              {product && (
                <PriceList
                  listPrice={product?.listPrice}
                  salesPrice={product?.salesPrice}
                  mode={"single"}
                  padding="16px"
                />
              )}
              {appInfo.isWholesaleApp && (
                <Tags>
                  {intl.get("products.salesCondition")}
                  <Content>
                    {intl.get("products.wholesaleMinProducts")} {product.wholesaleMinProducts}{intl.get("products.count")}
                  </Content>
                </Tags>
              )}
              {product?.tags && product?.tags.length > 0 && (
                <Tags>
                  {intl.get("products.tag")}
                  <Content>{product?.tags.map((v) => v.value).toString()}</Content>
                </Tags>
              )}
              <Tags>
                {intl.get("brand.name")}
                <Content>{product?.brandName}</Content>
              </Tags>
              {settingValues && settingValues.filter((v) => v.field === "delivery") && (
                <Tags>
                  {intl.get("order.orderDelivery")}
                  <Content>
                    {settingValues.filter((v) => v.field === "delivery")[0].value || intl.get("order.delivery-franchise")}
                  </Content>
                </Tags>
              )}
              {settingValues && settingValues.filter((v) => v.field === "originCountry") && (
                <Tags>
                  {intl.get("order.originCountry")}
                  <Content>
                    {settingValues.filter((v) => v.field === "originCountry")[0].value || product?.originCountry}
                  </Content>
                </Tags>
              )}
              <ItemSelect clicked={selectClicked}>
                <div className="default" onClick={onClickSelect} clicked={selectClicked.toString()}>
                  {intl.get("products.options")}
                  <OptionWrapper clicked={selectClicked}>
                    {product?.skus.map((sku, i) => {
                      return (
                        <SelectOption key={i} onClick={() => { onClickOption(sku)}}>
                          {sku.skuImage && (
                            <img src={sku.skuImage} alt="optionImg" />
                          )}
                          {sku.optionText}
                        </SelectOption>
                      );
                    })}
                  </OptionWrapper>
                </div>
              </ItemSelect>
              {selectedSkus.map((sku, i) => (
                <SelectedOption key={i}>
                  <div className="subject">{sku.optionText}</div>
                  <div className="bottomWrapper">
                    <Count className="count">
                      <PlusMinusBtn onClick={() => {onclickMinus(i)}}>-</PlusMinusBtn>
                      <input onChange={(e) => skuQuantityChanged(e, sku.id)} value={sku.quantity} />
                      <PlusMinusBtn onClick={() => {onclickPlus(i)}}>+</PlusMinusBtn>
                    </Count>
                    <SkuPrice salesPrice={sku.salesPrice} quantity={sku.quantity} index={i} onClick={onClickOptionCancel}/>
                  </div>
                </SelectedOption>
              ))}
              {totalPrice ? (
                <SkuTotalPrice originPriceTotal={totalPrice}
                               secondaryPriceTotal={selectedSkus?.reduce((acc, cur) => {
                                 return (cur.salesPrice.secondaryPrice * cur.quantity + acc)}, 0)}
                               paymentPriceTotal={0}
                />
              ) : null}
              <BuyBtn onClick={onClickBuyButton}>
                {intl.get("common.button.buy")}
              </BuyBtn>
            </ItemSummary>
          </ItemInfo>
          <ItemDetailImage src={product?.descImages || ""} />
        </>
      ) : (
        <>
          {appInfo.isWholesaleApp && !Util.isLoginUser() ? (
            <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
              <Alert
                message={"Warning"}
                description={intl.get("common.message.request-login")}
                type="warning"
                showIcon
              />
            </div>
          ) : (
            <ProductSkeleton />
          )}
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  margin-top: 78px;
  width: 100%;
`;

const ItemInfo = styled.div`
  display: flex;
  width: 100%;
  min-height: 484px;

  .subject {
    max-height: 60px;
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
`;

const ItemSummary = styled.div`
  margin: 5px 0px 5px 15px;
  box-sizing: border-box;
  width: 484px;
  height: 100%;

  div:nth-child(3) {
    border-top: 1px solid ${gray100};
  }
`;

const ItemImage = styled.img`
  width: 484px;
  height: 484px;
`;

const ItemDetailImage = styled.img`
  margin-top: 64px;
  width: 100%;
`;

const ItemSelect = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  /* border: 1px solid #e0e0e0; */
  cursor: pointer;
  margin: 16px 0;
  box-sizing: border-box;

  img {
    width: 48px;
    height: 48px;
    margin-right: 12px;
  }

  .default {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: url(${(props) => (props.clicked ? ArrowTop : SelectArrow)})
      no-repeat 95% center;
    width: 100%;
    height: 62px;
    padding: 0 0 0 16px;
    box-sizing: border-box;
    border: 1px solid #3352f6;
    box-sizing: border-box;
    border-radius: ${(props) => (props.clicked ? "2px 2px 0 0" : "2px")};
    border-collapse: collapse;
    background-position: right 15px bottom 50%;
    background-color: ${(props) => (props.clicked ? "#F5F5F5" : "white")};
    user-select: none;
  }
`;

const OptionWrapper = styled.div`
  position: absolute;
  display: ${(props) => (props.clicked ? "flex" : "none")};
  flex-direction: column;
  max-height: 248px;
  width: 484px;
  z-index: 999;
  background-color: ${mainColor2};
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  border: solid #3352f6;
  border-width: 0 1px 1px 1px;
  border-radius: 0 0 2px 2px;
  left: 0px;
  top: 62px;

  &::-webkit-scrollbar {
    background-color: ${gray100};
  }

  &::-webkit-scrollbar-track {
    width: 20px;
    background-color: ${gray100};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${gray300};
    background-clip: padding-box;
    border: solid transparent;
    border-width: 3px 5px;
    border-radius: 8px;
  }
`;

const SelectOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 484px;
  height: 62px;
  border: solid #e0e0e0;
  border-width: 0 0 1px;
  box-sizing: border-box;
  background-color: white;
  font-weight: normal;
  user-select: none;
  padding: 7px 16px;
  :hover {
    background-color: ${gray100};
  }
`;

const BuyBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 54px;
  font-size: 16px;
  font-weight: 700;
  background-color: ${mainColor2};
  color: white;
  cursor: pointer;
  margin: 16px 0; ;
`;

const Tags = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 16px;
  color: #616161;
  border-bottom: 1px solid ${gray100};
  padding: 12px 0;
`;

const Content = styled.div`
  max-width: 80%;
  margin-left: 16px;
  color: black;
`;

const SelectedOption = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 98px;
  background-color: ${gray100};
  margin-bottom: 16px;
  padding: 12px 16px;
  box-sizing: border-box;
  user-select: none;

  .subject {
    margin-bottom: 0;
    font-size: 16px;
  }

  .bottomWrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .priceWrapper {
    position: relative;
    display: flex;
    flex-direction: row;
  }

  .priceWon {
    font-weight: 700;
    font-size: 20px;
  }

  .priceYuan {
    font-size: 14px;
    line-height: 35px;
    margin-left: 4px;
  }

  .cancleBtn {
    position: relative;
    width: 12px;
    height: 12px;
    cursor: pointer;
    margin-left: 12px;
    top: 12px;
  }
`;

const Count = styled.div`
  display: flex;
  border: 1px solid ${gray300};
  width: 98px;
  height: 30px;
  user-select: none;
  input {
    text-align: center;
    width: 42px;
    height: 28px;
    // background-color: ${gray100};
    // pointer-events: none;
    border: none;
    border-left: 1px solid ${gray300};
    border-right: 1px solid ${gray300};
  }
`;

const PlusMinusBtn = styled.button`
  border: none;
  height: 28px;
  width: 28px;
  background-color: white;
  cursor: pointer;
`;

const TotalPrice = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin: 20px 0;
  padding: 0 16px;
  box-sizing: border-box;
  font-size: 16px;
`;
const TotalPriceText = styled.div`
  color: ${gray500};
  line-height: 36px;
`;
const TotalOriginPrice = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin: 0 8px 0 24px;
  line-height: 36px;
`;

const TotalSecondaryPrice = styled.div`
  margin-top: 5px;
  border-top: none !important;
`;
export default ProductDetail;
