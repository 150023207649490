import React from "react";

import Cookies from "universal-cookie";
import moment from "moment-timezone";
import ko_KR from "antd/lib/locale-provider/ko_KR";
import { MemberContext } from "../auth/member.context";

const zone = moment.tz.guess();
const cookies = new Cookies();

export default class Util {
  static ORDER_STATUSES = [
    { key: "created", text: "주문생성" },
    { key: "pay_prepared", text: "지불확인중" },
    { key: "wait_pay", text: "지불대기중" },
    { key: "paid", text: "지불완료" },
    { key: "pay_declared", text: "해관신고중(지불신고완료)" },
    { key: "order_declaring", text: "해관신고중(주문신고)" },
    { key: "order_declared", text: "해관신고중(주문신고완료)" },
    { key: "prepare_delivery", text: "통관완료/배송준비중" },
    { key: "delivering", text: "배송중" },
    { key: "delivered", text: "배송완료" },
    { key: "finished", text: "처리완료" },
    { key: "failed", text: "실패" },
  ];

  static ORDER_FAILED_REASONS = [
    { key: "none", text: "-" },
    { key: "prepare_pay", text: "지불준비실패" },
    { key: "payment", text: "지불실패" },
    { key: "pay_declared", text: "해관신고(지불신고)실패" },
    { key: "order_declared", text: "해관신고(주문신고)실패" },
    { key: "clearance", text: "해관통관신고실패" },
    { key: "etc", text: "기타" },
  ];

  static ORDER_FINISH_STATUS = [
    { key: "success", text: "성공" },
    { key: "failed", text: "실패" },
    { key: "user_cancel", text: "사용자 취소" },
    { key: "system_cancel", text: "시스템에 의한 취소" },
  ];

  static ORDER_DECLARE_STATUS = [
    { key: "success", text: "성공" },
    { key: "failed", text: "실패" },
    { key: "created", text: "정보생성완료" },
    { key: "wait_agent", text: "PC전송대기중" },
    { key: "agent_fetched", text: "PC전송완료" },
    { key: "sent", text: "보세구전송완료" },
  ];

  static getOrderDeclareStatus = (status) => {
    const found = Util.ORDER_DECLARE_STATUS.find((s) => s.key === status);
    if (found) {
      return found.text;
    } else {
      return "N/A";
    }
  };

  static getOrderStatusText = (status) => {
    const found = Util.ORDER_STATUSES.find((s) => s.key === status);
    if (found) {
      return found.text;
    } else {
      return "N/A";
    }
  };

  static getAppPageTypeName = (pageType) => {
    if (pageType === "index") {
      return "메인 페이지";
    } else if (pageType === "product") {
      return "제품 구매 상세 페이지";
    } else {
      return "서브 페이지";
    }
  };

  static humanFileSize(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + " B";
    }

    const units = si
      ? ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
    );

    return bytes.toFixed(dp) + " " + units[u];
  }

  static isAdmin = () => {
    return MemberContext.memberInformation.userType === "admin";
  };

  static getUserTypeName = (userType) => {
    if (userType === "admin") {
      return "시스템 관리자";
    } else if (userType === "supplier") {
      return "회사 사용자";
    } else if (userType === "seller") {
      return "판매사";
    } else if (userType === "draft") {
      return "미승인";
    } else {
      return "";
    }
  };
  static getOssBucket = () => {
    const merchandiserDomain = cookies.get("merchandiserDomain") || "eryuan";
    if (merchandiserDomain === "topsdf") {
      return "https://tpstns-oss.oss-cn-hongkong.aliyuncs.com";
    } else {
      return "https://eryuan-oss.oss-cn-beijing.aliyuncs.com";
    }
  };

  static getSymbol = (currency) => {
    if (currency === "KRW") {
      return "₩";
    } else if (currency === "USD") {
      return "$";
    } else if (currency === "CNY") {
      return "¥";
    } else {
      return "ERR";
    }
  };

  // static getCurrencySymbol = () => {
  //   return Util.getSymbol(layoutState.application.currency);
  // }
  //
  // static getBaseCurrencySymbol = () => {
  //   return Util.getSymbol(layoutState.application.baseCurrency);
  // }

  static currencyFormat = (num, currency) => {
    let symbol = Util.getSymbol(currency);

    if (!num) {
      return symbol + "0";
    }
    if ((num * 100) % 100 === 0) {
      num = Math.round(num);
      return symbol + num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    } else {
      return symbol + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }
  };

  static numberFormat = (num) => {
    if (!num) {
      return "0";
    }
    if ((num * 100) % 100 === 0) {
      num = Math.round(num);
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    } else {
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }
  };

  static deleteAllCookies = () => {
    const allCookies = document.cookie.split(";");
    const domainName = "localhost"; //Config.DOMAIN();
    for (let i = 0; i < allCookies.length; i++) {
      const cookie = allCookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      const deleteCookie = `${name}=; domain=${domainName}; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
      console.log("===> deleteCookie:", deleteCookie);
      document.cookie = deleteCookie;
    }
  };

  static isLoginUser = () => {
    return cookies.get("userId") || "" !== "";
  }
  static getMerchandiserId = () => {
    return cookies.get("merchandiserId") || "";
  };

  static getUser = () => {
    return cookies.get("user") || {};
  };

  static getLocale = () => {
    return cookies.get("locale") || ko_KR.locale;
  };

  static getChinaLocalTime(dateTimeStr) {
    return (
      moment.tz(dateTimeStr, "Asia/Shanghai").format("YYYY-MM-DD HH:mm:ss") +
      "(GMT+8)"
    );
  }

  static getLocalTime(dateTimeStr) {
    return moment.tz(dateTimeStr, zone).format("YYYY-MM-DD HH:mm:ss");
  }

  static getLocalTimeOrDate(dateTimeStr) {
    const localTime = moment.tz(dateTimeStr, zone);
    const dataDate = localTime.format("YYYY-MM-DD");
    const currentDate = moment().tz(zone).format("YYYY-MM-DD");
    if (dataDate !== currentDate) {
      return (
        <span>
          {localTime.format("MM/DD")}
          <br />
          {localTime.format("HH:mm A")}
        </span>
      );
    } else {
      return moment.tz(dateTimeStr, zone).format("HH:mm A");
    }
  }

  static toFixed2(num) {
    return Math.round(num * 100) / 100;
  }

  static encodeImagePath = (path) => {
    // filename에 "+" 기호가 있는 경우 제대로 나타나지 않는다.
    try {
      const url = new URL(path);
      if (path.endsWith("/")) {
        path = path.substring(0, path.length - 1);
      }

      const index = path.lastIndexOf("/");
      if (index < url.origin.length) {
        return path;
      }

      const fileName = path.substring(index + 1);
      const encodedPath =
        path.substring(0, index) + "/" + encodeURIComponent(fileName);
      return encodedPath;
    } catch (err) {
      return path;
    }
  };
}
