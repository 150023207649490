import React from "react";
import styled from "styled-components";

function Subject({
  children,
  onClick = function () {},
  cursor = "",
  orderId = "",
  fontSize = "24px",
  height = "57px",
  paddingBottom = "0px",
}) {
  return (
    <Container
      onClick={() => {
        onClick(orderId);
      }}
      cursor={cursor}
      fontSize={fontSize}
      height={height}
      paddingBottom={paddingBottom}
    >
      {children}
    </Container>
  );
}

const Container = styled.li`
  width: 100%;
  height: ${(props) => (props.height ? props.height: "")};
  box-sizing: border-box;
  font-size: ${(props) => (props.fontSize ? props.fontSize : `18px`)};
  font-weight: 700;
  border-bottom: ${(props) =>
    props.cursor ? `1px solid #212121` : `2px solid #212121`};
  margin-top: 84px;
  cursor: ${(props) => props.cursor};
  padding-bottom: ${(props) => (props.paddingBottom ? props.paddingBottom : "0")};
  :first-child {
    margin-top: 0;
  }
`;

export default Subject;
