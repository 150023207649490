import React, { useContext } from "react";
import styled from "styled-components";
import CancelBtn from "../../assets/cancelBtn.png";

import AppInfoContext from "../../contexts/AppInfoContext";

function SkuPrice({ salesPrice, quantity, index, onClick }) {
  const { appInfo, appDesign } = useContext(AppInfoContext);
  const { priceDisplaySetting } = appDesign;

  let showSubCurrency = false;
  let mainPriceSymbol = "¥";
  let subPriceSymbol = "¥";
  let salePriceMainAmount = 0;
  let salePriceSubAmount = 0;

  if (salesPrice) {
    showSubCurrency = priceDisplaySetting.subCurrency !== "none" && appInfo.secondaryCurrency !== appInfo.productCurrency;

    /////////////////////////////////////////////////////
    // main price 설정
    salePriceMainAmount = salesPrice.secondaryPrice;
    if (priceDisplaySetting?.mainCurrency === "product") {
      mainPriceSymbol = appInfo.productSymbol;
      salePriceMainAmount = salesPrice.originPrice;
    } else if (priceDisplaySetting?.mainCurrency === "payment") {
      mainPriceSymbol = appInfo.paymentSymbol;
      salePriceMainAmount = salesPrice.paymentPrice;
    }
    // main price 설정
    /////////////////////////////////////////////////////

    /////////////////////////////////////////////////////
    // sub price 설정
    subPriceSymbol = "¥";
    salePriceSubAmount = salesPrice.secondaryPrice;
    if (priceDisplaySetting?.subCurrency === "product") {
      subPriceSymbol = appInfo.productSymbol;
      salePriceSubAmount = salesPrice.originPrice;
    } else if (priceDisplaySetting?.subCurrency === "payment") {
      subPriceSymbol = appInfo.paymentSymbol;
      salePriceSubAmount = salesPrice.paymentPrice;
    }
  }

  return (
    <SkuPriceWrapper>
      <div className="priceWrapper">
        <div className="priceWon">
          {mainPriceSymbol}
          {Number(salePriceMainAmount * quantity).toLocaleString("ko-KR")}
        </div>
        {showSubCurrency && (
        <div className="priceYuan">
          {subPriceSymbol}
          {Number(salePriceSubAmount * quantity).toLocaleString("kr-KR")}
        </div>
        )}
        <img className="cancleBtn" src={CancelBtn} alt="cancelBtn" onClick={() => {onClick(index)}}/>
      </div>
    </SkuPriceWrapper>
  );
}

const SkuPriceWrapper = styled.div`
  .bottomWrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .priceWrapper {
    position: relative;
    display: flex;
    flex-direction: row;
  }

  .priceWon {
    font-weight: 700;
    font-size: 20px;
  }

  .priceYuan {
    font-size: 14px;
    line-height: 35px;
    margin-left: 4px;
  }

  .cancleBtn {
    position: relative;
    width: 12px;
    height: 12px;
    cursor: pointer;
    margin-left: 12px;
    top: 12px;
  }
`;

export default SkuPrice;
