import React from "react";
import intl from "react-intl-universal";
import styled from "styled-components";

import FailedSign from "../../assets/failed.png";

import GoHomeBtn from "../../components/buttons/GoHomeBtn";

function OrderFailed() {
  return (
    <Container>
      <div className="successImg">
        <img src={FailedSign} alt="failedImg" />
      </div>
      <span>{intl.get("order.order") + intl.get("order.status.failed")}</span>
      <GoHomeBtn>{intl.get("common.button.go-home")}</GoHomeBtn>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 145px auto;
  font-size: 28px;
  font-weight: 700;

  .successImg {
    height: 108px;
  }

  span {
    margin: 48px 0;
  }
`;

export default OrderFailed;
