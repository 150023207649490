import React, {useState, useCallback, useEffect} from "react";
import intl from "react-intl-universal";
import styled from "styled-components";
import Cookies from "universal-cookie";
import TermsApi from "../../components/apis/TermsApi";

const cookies = new Cookies();

const Terms = (props) => {
  const type = props.match.params.type;
  const [terms, setTerms] = useState("");

  useEffect(() => {
    const language = getLanguage();
    TermsApi.getTerms().then(res => {
      const termsList = res.data;
      let foundTerms = termsList.find(t => t.termsType === type && t.language === language);
      if (!foundTerms) {
        foundTerms = termsList.find(t => t.termsType === type);
      }
      if (foundTerms) {
        setTerms(foundTerms.publishedTerms);
      } else {
        setTerms("");
      }
    });
  }, [type])

  const getLanguage = () => {
    let language = cookies.get('locale');
    if (language !== "zh-CN" && language !== "ko-KR") {
      const lang = navigator.language || navigator.userLanguage;
      // en-US, zh-CN, zh, ko
      if (lang.startsWith("zh")) {
        language = "zh-CN";
      } else {
        language = "ko-KR";
      }
    }
    return language;
  };

  return (
    <Container>
      <Title>{intl.get("terms.termsType." + type)}</Title>
      <pre style={{fontSize: 14}}>
        {terms}
      </pre>
    </Container>
  )
}
const Container = styled.div`
  margin-top: 78px;
  width: 100%;
`;

const Title = styled.div`
  font-size: 36px;
  font-weight: bold;
  width: 100%;
  margin-bottom: 30px;
`;

export default Terms;