import React, { useCallback, useState, useEffect, useContext } from "react";
import styled from "styled-components";
import intl from "react-intl-universal";

import {
  EventBroadcaster,
  MEMBER_CONTEXT_AVAILABLE_EVENT_TOPIC,
} from "../../event/event.broadcaster";

import SearchImg from "../../assets/icon.png";
import LogoSvg from "../../assets/logo.svg";
// import { ReactComponent as LogoSvg } from "../../assets/logo.svg";
import { ReactComponent as SelectArrow } from "../../assets/langSelectArrow.svg";
import { ReactComponent as SelectArrowClicked } from "../../assets/langSelectArrowClicked.svg";
import { gray200, gray500 } from "../../styles/fontColors";

import { useHistory } from "react-router-dom";
import { deleteCookie, setCookie, getCookie } from "../../cookie/setCookie";
import { MemberContext } from "../../auth/member.context";
import { AuthService } from "./../../auth/auth.service";
import Cookies from "universal-cookie";
import AppInfoContext from "../../contexts/AppInfoContext";
import NoImage from "../../assets/no_image.png";

function Header({ handleModal, changeSearchValue, changeLogoClicked }) {
  const { appDesign } = useContext(AppInfoContext);

  const url = document.location.href.split("/");
  const history = useHistory();
  const cookies = new Cookies();

  const [searchValue, setSearchValue] = useState("");
  const [user, setUser] = useState({});
  const [langSelectorClicked, setLangSelectorClicked] = useState(false);

  useEffect(() => {
    EventBroadcaster.on(MEMBER_CONTEXT_AVAILABLE_EVENT_TOPIC, () => {
      setUser(MemberContext.memberInformation);
    });
  }, []);

  const onClickBtns = (path) => {
    // if (path === "login") {
    //   handleModal("login");
    //   return;
    // }
    // if (path === "loginId") {
    //   handleModal("loginId");
    //   return;
    // }
    // // if (path === "signup") {
    // //   handleModal("signup");
    // //   return;
    // // }
    // if (path === "find-password") {
    //   handleModal(path);
    //   return;
    // }
    setSearchValue("");
    changeSearchValue("");
    if (path === "home") {
      changeSearchValue("");
      changeLogoClicked(true);
      history.replace("/");
      return;
    }
    history.push(`/${path}`);
  };

  const onChangeSearchValue = useCallback((e) => {
    setSearchValue(e.target.value);
  }, []);

  const submitSearch = useCallback(
    (e) => {
      e.preventDefault();
      history.push("/");
      changeSearchValue(searchValue);
    },
    [changeSearchValue, searchValue, history]
  );

  const onClickLogout = () => {
    const allCookies = cookies.getAll();

    AuthService.logout()
      .then(() => {
        for (let cookie in allCookies) {
          // if (cookie === "appId" || cookie === "locale") {
          if (cookie === "locale") {
            continue;
          }
          deleteCookie(cookie);
        }
        MemberContext.memberInformation = {};
        window.location.href = "/";
      })
      .catch(() => {});
  };

  const handleLanguageClick = (v) => {
    // changeLocale({ type: "CHANGE_LOCALE", v });
    setCookie("locale", v);
    document.location.href = "/";
  };

  const onClickLangSelector = () => {
    setLangSelectorClicked((prev) => !prev);
  };

  let logo = LogoSvg;
  if (appDesign?.themeSetting?.webmall?.logo) {
    logo = appDesign.themeSetting.webmall.logo
  } else if (appDesign?.appLogoImage) {
    logo = appDesign.appLogoImage;
  }
  return (
    <Container>
      <Content>
        <HeaderLogo
          onClick={() => {
            onClickBtns("home");
          }}
        >
          <img src={logo} style={{height: 40, width: 120}}/>
        </HeaderLogo>
        <SearchForm onSubmit={submitSearch}>
          {!url.some((v) => ["mypage", "order"].includes(v)) && (
            <SearchInput
              name="searchInput"
              onChange={onChangeSearchValue}
              value={searchValue}
            />
          )}
        </SearchForm>
        {getCookie("userId") || user.id ? (
          <>
            <LoginSignupBtn
              onClick={() => {
                onClickBtns(`mypage`);
              }}
            >
              {intl.get("header.mypage")}({user.name})
            </LoginSignupBtn>
            <LoginSignupBtn onClick={onClickLogout}>
              {intl.get("header.logout")}
            </LoginSignupBtn>
          </>
        ) : (
          <>
            <LoginSignupBtn
              onClick={() => {
                onClickBtns("login");
              }}
            >
              {intl.get("header.login")}
            </LoginSignupBtn>
            <LoginSignupBtn
              onClick={() => {
                onClickBtns("signup");
              }}
            >
              {intl.get("header.signup")}
            </LoginSignupBtn>
          </>
        )}
        <LangSelector onClick={onClickLangSelector}>
          {getCookie("locale") === "ko-KR" ? "한국어" : "简体中文"}{" "}
          {langSelectorClicked ? (
            <SelectArrowClicked style={{ marginLeft: "10px" }} />
          ) : (
            <SelectArrow style={{ marginLeft: "10px" }} />
          )}
          <LangPopup clicked={langSelectorClicked}>
            <div
              onClick={() => {
                handleLanguageClick("ko-KR");
              }}
            >
              한국어
            </div>
            <div
              onClick={() => {
                handleLanguageClick("zh-CN");
              }}
            >
              简体中文
            </div>
          </LangPopup>
        </LangSelector>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  border-bottom: 1px solid #eaeaea;
  margin: 0 auto;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 86px;
  width: 988px;
  margin: auto;
`;

const SearchForm = styled.form`
  margin: 0 auto 0 74px;
`;

const SearchInput = styled.input`
  width: 460px;
  height: 40px;
  border: 2px solid #3352f6;
  box-sizing: border-box;
  border-radius: 20px;
  padding-left: 34px;
  background-image: url(${SearchImg});
  background-repeat: no-repeat;
  background-position: center right 18px;
  background-size: 16px;
  font-size: 14px;
  :focus {
    outline: none;
  }
`;
const LoginSignupBtn = styled.div`
  position: relative;
  font-size: 13px;
  color: ${gray500};
  margin-left: 13px;
  cursor: pointer;
  user-select: none;
  bottom: 10px;
`;

// const HeaderLogo = styled(LogoSvg)`
//   cursor: pointer;
// `;

const HeaderLogo = styled.div`
  cursor: pointer;
`;

const LangSelector = styled.div`
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: black;
  bottom: 10px;
  margin-left: 32px;
  cursor: pointer;
  user-select: none;
`;

const LangPopup = styled.div`
  position: absolute;
  display: ${(props) => (props.clicked ? "flex" : "none")};
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  top: 26px;
  width: 78px;
  height: 72px;
  right: -5px;
  border: 1px solid ${gray200};
  box-sizing: border-box;
  border-radius: 2px;
  background-color: white;
  z-index: 999;
  animation: growDown 200ms ease-in-out forwards;
  transform-origin: top center;
  @keyframes growDown {
    0% {
      transform: scaleY(0);
    }
    80% {
      transform: scaleY(1.1);
    }
    100% {
      transform: scaleY(1);
    }
  }
`;

export default Header;
