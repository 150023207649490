import React, {useContext} from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";

import { gray400, gray600 } from "../../styles/fontColors";
import AppInfoContext from "../../contexts/AppInfoContext";

function Footer() {
  const { appInfo, systemSettings } = useContext(AppInfoContext);
  const hostName = window.location.hostname;

  const footerContactLine = appInfo?.footerContact ? appInfo.footerContact.split("\n") : [];
  const footerRegisterNoLine = appInfo?.footerRegisterNo ? appInfo.footerRegisterNo.split("\n") : [];
  const serviceDomain = systemSettings && systemSettings["service.domain"] ? systemSettings["service.domain"] : "";

  return (
    <Container>
      <Content>
        <CompanyWrapper>
          <Info>
            {footerContactLine.map((line, index) => (
              <div key={index}>{line}</div>
            ))}
          </Info>
          {serviceDomain !== "eryuan.co" && (
            <Info>
              <div style={{display:"flex", gap: 10}}>
                <div>
                  <Link to={"/terms/user"} target="_blank">이용약관</Link>
                </div>
                <div>
                  <Link to={"/terms/privacy"} target="_blank">개인정보처리방침</Link>
                </div>
              </div>
            </Info>
          )}
          <Copyright>{appInfo.footerCopyright}</Copyright>
          {serviceDomain !== "eryuan.co" && (
            <Copyright>서비스 제공회사: 베터코드 주식회사</Copyright>
          )}
        </CompanyWrapper>
        <BizNum>
          <Info>
            {footerRegisterNoLine.map((line, index) => (
              <div key={index}>{line}</div>
            ))}
          </Info>
          {appInfo.qrCode && (
            <img src={appInfo.qrCode} style={{width: 50, height: 50}}/>
          )}
        </BizNum>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 80px auto 0;
  padding: 50px 0;
  background-color: #f3f3f3;
  font-size: 13px;
  box-sizing: border-box;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 988px;
  font-weight: 400;
  font-size: 14px;
`;

const BizNum = styled.div`
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: ${gray600};
`;

const CompanyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90%;
`;

const Info = styled.div`
a { color: inherit; } 
`;

const Copyright = styled.div`
  color: ${gray400};
`;

export default Footer;
