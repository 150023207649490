import { createContext } from "react";

export const initialState = {
  searchValue: "",
  clicked: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_VALUE":
      return {
        searchValue: action.searchValue,
        clicked: state.clicked,
      };
    case "CHANGE_CLICKED":
      return {
        searchValue: state.searchValue,
        clicked: action.clicked,
      };
    default:
      return state;
  }
};

const SearchContext = createContext(initialState);

export default SearchContext;
