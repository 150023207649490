import axios from "axios";
import { adminConfig } from "../../config/admin.config";

const API_BASE_URL = adminConfig.apiServer();

export default class ProductApi {
  static getProducts = (params) => {
    let apiPath = `${API_BASE_URL}/products?`;

    let paramKeyValues = [];
    Object.keys(params).forEach((key) => {
      if (params[key]) {
        const paramValue = params[key];
        if (Array.isArray(paramValue)) {
          paramKeyValues = paramKeyValues.concat(
            paramValue.map((v) => key + "=" + v)
          );
        } else {
          paramKeyValues.push(key + "=" + paramValue);
        }
      }
    });
    apiPath += paramKeyValues.join("&");

    return axios.get(apiPath);
  };

  static getProduct = (productId) => {
    const apiPath = `${API_BASE_URL}/products/${productId}`;
    return axios.get(apiPath);
  };

  static getProductShares = (params) => {
    let apiPath = `${API_BASE_URL}/products/share?`;

    let paramKeyValues = [];
    Object.keys(params).forEach((key) => {
      if (params[key]) {
        const paramValue = params[key];
        paramKeyValues.push(key + "=" + paramValue);
      }
    });
    apiPath += paramKeyValues.join("&");
    apiPath += "&projection=webAdmin";

    return axios.get(apiPath);
  };

  static getProductCoupons = (productId) => {
    const apiPath = `${API_BASE_URL}/products/${productId}/coupons?projection=webAdmin`;
    return axios.get(apiPath);
  };

  static saveProduct = (product) => {
    if (product.id) {
      let apiPath = `${API_BASE_URL}/products/${product.id}`;
      return axios.put(apiPath, product);
    } else {
      let apiPath = `${API_BASE_URL}/products`;
      return axios.post(apiPath, product);
    }
  };

  static saveProducts = (data) => {
    let apiPath = `${API_BASE_URL}/products/batch`;
    return axios.put(apiPath, data);
  };

  static deleteProduct = (productId) => {
    const apiPath = `${API_BASE_URL}/products/${productId}`;
    return axios.delete(apiPath);
  };

  // static download = (params) => {
  //   let apiPath = `${API_BASE_URL}/products/download?projection=webAdmin&`;
  //
  //   const paramKeyValues = []
  //   Object.keys(params).forEach(key => {
  //     if (params[key]) {
  //       paramKeyValues.push(key + "=" + params[key])
  //     }
  //   });
  //   apiPath += paramKeyValues.join("&")
  //
  //   return fetch(apiPath, {headers: HttpUtil.getHeader()})
  //       .then(HttpUtil.handleHttpStatus)
  //       .catch(HttpUtil.handleHttpStatusError)
  // };

  static getProductsByPageItem = (page) => {
    let apiPath = `${API_BASE_URL}/products?page=${page}`;
    return axios.get(apiPath);
  };

  static getAllTags = () => {
    let apiPath = `${API_BASE_URL}/products/tags?projection=webAdmin&`;
    return axios.get(apiPath);
  };

  static getTags = (params) => {
    let apiPath = `${API_BASE_URL}/products/tags?projection=webAdmin&`;

    apiPath += Object.keys(params)
      .map((key) => {
        return key + "=" + params[key];
      })
      .join("&");

    return axios.get(apiPath);
  };
}
