import React from "react";
import styled from "styled-components";
import { Switch } from "react-router-dom";

import PageLoadingRoute from "../../components/router/PageLoadingRoute";
import Home from "../Home";
import ErrorPage from "../ErrorPage";
import LoginWithSMS from "../login/LoginWithSMS";
import LoginSuccess from "../login/LoginSuccess";
import LoginIdPwd from "../login/LoginIdPwd";
import Signup from "../login/Signup";
import FindPasswordMail from "../login/FindPasswordMail";
import ProductDetail from "../productDetail/ProductDetail";
import Mypage from "../mypage/Mypage";
import OrderFailed from "../orderFailed/OrderFailed";
import OrderSuccess from "../orderSuccess/OrderSuccess";
import PrepareOrder from "../prepareOrder/PrepareOrder";
import OrderDetail from "../orderDetail/OrderDetail";
import OrderPay from "../orderPay/OrderPay";
import ResetPassword from "../login/ResetPassword";
import Terms from "../terms/Terms";

function PageRouter({ handleModal, changeLogoClicked }) {
  return (
    <Switch>
      <Container>
        <PageLoadingRoute
          exact
          path="/"
          render={() => <Home changeLogoClicked={changeLogoClicked}/>}
        />
        <PageLoadingRoute exact path="/login" component={LoginIdPwd} />
        <PageLoadingRoute exact path="/login-success" component={LoginSuccess} />
        <PageLoadingRoute exact path="/signup" component={Signup} />
        <PageLoadingRoute exact path="/find-password" component={FindPasswordMail} />
        <PageLoadingRoute exact path="/reset-password" component={ResetPassword} />
        <PageLoadingRoute exact path="/mypage" render={() => <Mypage handleModal={handleModal} />} />
        <PageLoadingRoute exact path="/products/:productId" component={ProductDetail}/>
        <PageLoadingRoute exact path="/prepare-order" render={() => <PrepareOrder handleModal={handleModal}/>} />
        <PageLoadingRoute exact path="/order/:orderNo"
                          render={(props) => ( <OrderDetail handleModal={handleModal} {...props} /> )} />
        <PageLoadingRoute exact path="/order/:orderNo/pay" component={OrderPay} />
        <PageLoadingRoute exact path="/failed" component={OrderFailed} />
        <PageLoadingRoute exact path="/success" component={OrderSuccess} />
        <PageLoadingRoute exact path="/terms/:type" component={Terms} />
      </Container>
      <PageLoadingRoute path="*" component={ErrorPage} />
    </Switch>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 988px;
  margin: 0 auto;
  min-height: 720px;
  /* background-color: lightgray; */
`;

export default PageRouter;
