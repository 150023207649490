const messageKoKR = {
  "header.mypage": "마이페이지",
  "header.login": "로그인",
  "header.logout": "로그아웃",
  "header.signup": "회원가입",
  "header.findPassword": "비밀번호찾기",
  "header.resetPassword": "비밀번호설정",

  "common.button.ok": "확인",
  "common.button.cancel": "취소",
  "common.button.buy": "구매하기",
  "common.button.pay": "결제하기",
  "common.button.verify": "인증번호받기",
  "common.button.go-home": "홈으로 가기",
  "common.button.show": "보기",

  "common.veryfication-code": "인증번호",
  "common.message.sent-code": "전화번호[{name}]로 인증번호가 전송되었습니다. 핸드폰에서 받은 인증번호 입력 후 가입하기 버튼을 클릭하세요.",
  "common.message.get-code": "SMS 인증번호를 받은 후 인증 번호를 입력하세요.",
  "common.message.contactAdmin": "문제가 발생했습니다. 시스템 관리자에게 문의하세요.",
  "common.message.request-login": "로그인 후 이용 가능합니다.",
  "common.message.moveToLogin": "로그인 화면으로 이동하시겠습니까?",
  "common.message.complete-order": "주문이 완료되었습니다.",
  "common.message.timer": "남은시간: ",
  "common.message.timer-over": "시간이 초과되었습니다.",
  "common.message.selectAtLeastOne": "하나 이상의 {name}을 선택하세요.",
  "common.message.login": "로그인 후 사용하세요.",
  "common.inputField": "{name}을(를) 입력하세요.",
  "common.message.saved": "저장되었습니다.",
  "common.message.notFound": "요청한 정보가 존재하지 않습니다.",

  "common.label.count": "건",
  "common.label.all": "전체",

  "common.error.1": "사용자정보없음",
  "common.error.2": "횟수를 초과했습니다. 10분후 다시 시도하세요.",
  "common.error.3": "인증코드를 입력하세요.",
  "common.error.4": "인증코드가 일치하지 않습니다.",
  "common.error.5": "인증코드 타입이 잘못되었습니다.",
  "common.error.6": "인증코드 유효 시간이 경과되었습니다. 다시 인증하세요.",
  "common.error.7": "이미 등록된 전화번호입니다.",
  "common.error.8": "인증코드가 존재하지 않습니다.",
  "common.error.10": "이미 존재하는 사용자ID 입니다.",
  "common.error.11": "비밀번호는 8자리 이상 입력하세요.",
  "common.error.20": "사용자ID 또는 비밀번호가 잘못되었습니다.",
  "common.error.21": "등록되지 않은 사용자ID 입니다.",
  "common.error.22": "시간이 초과되었습니다. SMS 인증을 다시 하세요.",
  "common.error.23": "전화번호가 일치하지 않습니다.",
  "common.error.999": "문제가 발생했습니다. 시스템 관리자에게 문의하세요.",

  "user.info": "사용자정보",
  "user.name": "이름",
  "user.phone": "전화번호",
  "user.email": "이메일",
  "user.loginId": "로그인 ID",
  "user.password": "비밀번호",
  "user.confirmingPassword": "비밀번호 확인",
  "user.message.diffPassword": "비밀번호가 다릅니다. 동일하게 입력해주세요",
  "user.message.registered": "회원 가입되었습니다.",
  "user.message.shortLoginId": "ID는 6자리 이상 입력하세요.",
  "user.message.shortPassword": "비밀번호는 8자리 이상 입력하세요.",
  "user.message.password.changed": "비밀번호가 변경되었습니다.",
  "user.message.agree1": "이용 약관에 동의하세요",
  "user.message.agree2": "개인정보 수집 및 이용에 동의하세요",
  "user.message.sentPasswordMail": "패스워드 설정 정보를 메일로 보냈습니다.",

  "terms.termsType.user": "이용약관",
  "terms.termsType.privacy": "개인정보 수집 및 이용",
  "terms.selectAll": "모두 동의합니다.",

  "category": "분류",
  "category.first": "대분류",
  "category.second": "소분류",

  "products.tag": "태그",
  "products.options": "제품 옵션",
  "products.salesCondition": "판매조건",
  "products.wholesaleMinProducts": "최소구매수량",
  "products.message.minNums": "최소구매수량은 {count}개 입니다.",
  "products.count": "개",

  "order.status.wait-review": "평가대기",
  "order.list": "주문목록",
  "order.orderInfo": "주문정보",
  "order.realName": "실명",
  "order.addressInput": "주소입력",
  "order.userComment": "메모",
  "order.means-of-payment": "지불수단",
  "order.delivery-franchise": "가맹점 배송",
  "order.originCountry": "원산지",
  "order.province": "성", // 省
  "order.city": "도시", // 市
  "order.district": "구", // 区
  "order.address-detail": "상세주소",
  "order.productName": "제품명",
  "order.order": "주문",
  "order.list.paymentInfo": "지불정보",
  "order.buyerInfo": "구매자정보",
  "order.list.recipientInfo": "수령인정보",
  "order.detail": "주문상세",
  "order.orderNo": "주문번호",
  "order.userName": "주문자성명",
  "order.phone": "전화번호",
  "order.status": "상태",
  "order.orderAmount": "상품가격",
  "order.paymentAmount": "지불금액",
  "order.paymentAmountPay": "지불요청금액",
  "order.paymentAmountUser": "실제지불금액",
  "order.orderDate": "주문일자",
  "order.finishedAt": "종료일자",
  "order.idCardNo": "신분증 번호",
  "order.recipient": "수령인",
  "order.address": "주소",
  "order.productAmount": "제품판매가격",
  "order.discountAmount": "할인",
  "order.deliveryFee": "배송료",
  "order.deliveredAt": "배송일자",
  "order.refund": "환불",
  "order.refundAt": "환불일자",
  "order.orderDelivery": "배송",
  "order.offerListPrice": "제품 가격(개당)",
  "order.quantity": "수량",
  "order.sku.shipQuantity": "수량",
  "order.wechatpay": "위챗 지불",
  "order.account_transfer": "계좌이체",

  "order.status.": " ",
  "order.status.processing_clearance_9610": "해관통관처리중(9610)",
  "order.status.created": "생성완료",
  "order.status.prepared_wx_prepay": "지불확인중",
  "order.status.pay_prepared": "지불대기중",
  "order.status.paid": "지불완료",
  "order.status.wait_pay": "지불대기",
  "order.status.wx_customs_success": "지불단 신고완료",
  "order.status.pay_declared": "해관신고중(지불신고완료)",
  "order.status.pay_declared1": "지불신고완료",
  "order.status.send_customs_success": "통관중",
  "order.status.declaring": "통관중",
  "order.status.declared": "통관완료",
  "order.status.order_declaring": "해관신고중(주문신고)",
  "order.status.order_declared": "해관신고중(주문신고완료)",
  "order.status.order_declared1": "주문신고완료",
  "order.status.preparing_delivery": "배송준비중",
  "order.status.prepare_delivery": "배송준비중",
  "order.status.delivering": "배송중",
  "order.status.delivered": "배송완료",
  "order.status.finished": "처리완료",
  "order.status.failed": "실패",
  "order.status.refund": "환불",
  "order.status.cancel": "취소",
  "order.status.user_cancel": "사용자취소",
  "order.status.system_cancel": "자동취소",
  "order.failed.payment_prepay_failed": "지불준비실패",
  "order.failed.payment_failed": "지불실패",
  "order.failed.call_wx_customdeclareorder_failed": "지불단 요청 실패",
  "order.failed.call_trade_push_failed": "해관 수입신고 전송 실패",
  "order.failed.trade_push_result_failed": "해관 통관 미통과",
  "order.status.success": "성공",
  "order.status.wait_agent": "PC전송대기중",
  "order.status.agent_fetched": "PC전송완료",
  "order.status.sent": "보세구전송완료",
  "order.reason.prepare_pay": "지불준비실패",
  "order.reason.payment": "지불실패",
  "order.reason.pay_declared": "해관신고(지불신고)실패",
  "order.reason.order_declared": "해관신고(주문신고)실패",
  "order.reason.clearance": "해관통관신고실패",
  "order.reason.etc": "기타",
  "order.reason.none": " ",

  "order.delivery.logisticsCompany": "물류기업",
  "order.deliveryNo": "배송번호",
  "order.delivery.status": "배송상태",
  "order.delivery.tracking": "배송조회",
  "order.delivery.noTracking": "배송정보가 없습니다.",
  "order.deliveryInfo": "배송정보",
  "order.delivery.input": "주문자 정보 및 배송지 정보를 입력하세요.",
  "order.created": "주문이 접수 되었습니다.",
  "order.cancel": "주문취소",
  "order.cancel.confirm": "주문을 취소 하시겠습니까?",
  "order.cancel.saved": "취소되었습니다.",
  "order.message.krw1": "웹에서 구매시 통화는 KRW로만 지불이 가능합니다.",
  "order.message.krw2": "아래 미니프로그램에서 주문하면 위챗 지불을 사용할 수 있습니다.",
  "order.payment.error": "지불 처리시 문제가 발생했습니다. 시스템 관리자에게 문의하세요.",
  "order.change.shippingAddress": "배송지변경",

  "brand.name": "브랜드명",

  "address.otherCounrty": "중국(대륙)이 아닌 경우 상세주소에 전체주소를 입력하세요."
};

export default messageKoKR;
