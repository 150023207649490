import React, { useCallback } from "react";
import intl from "react-intl-universal";
import styled from "styled-components";
import Util from "../../utils/Util";

import SuccessSign from "../../assets/success.png";
import { gray500, gray600 } from "../../styles/fontColors";

import GoHomeBtn from "../../components/buttons/GoHomeBtn";
import {useLocation} from "react-router-dom";

function OrderSuccess(props) {
  const location = useLocation();
  const stateParam = location.state;
  const {orderId, orderNo, orderDate} = stateParam;
  const contentWrapper = useCallback((subject, number) => {
    return (
      <ContentWrapper>
        <Subject>{subject}</Subject>
        <Content>{number}</Content>
      </ContentWrapper>
    );
  }, []);

  return (
    <Container>
      <div className="failedImg">
        <img src={SuccessSign} alt="failedImg" />
      </div>
      <span>{intl.get("common.message.complete-order")}</span>
      {contentWrapper(intl.get("order.orderNo"), orderNo)}
      {contentWrapper(intl.get("order.orderDate"), Util.getLocalTime(orderDate).substring(0, 10))}
      <BtnsWrapper>
        <GoHomeBtn
          location={"/order/" + orderId}
        >
          {intl.get("order.detail")}
        </GoHomeBtn>
        <GoHomeBtn
          borderColor={gray500}
          backgroundColor="white"
          fontColor={gray500}
          location={"/"}>
          {intl.get("common.button.go-home")}
        </GoHomeBtn>
      </BtnsWrapper>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 145px auto;
  font-size: 28px;
  font-weight: 700;

  .failedImg {
    height: 108px;
  }

  span {
    margin: 48px 0 32px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 20px;
  margin-bottom: 16px;
`;

const Subject = styled.div`
  color: ${gray600};
  margin-right: 20px;
`;

const Content = styled.div`
  color: ${gray500};
  font-weight: 400;
`;

const BtnsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 344px;
  margin: 32px auto;
`;

export default OrderSuccess;
