import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import PriceList from "../priceList/PriceList";
import { Skeleton } from 'antd';

function ProductItem({ itemId = 1, name, listPrice, salesPrice, images = [] }) {
  const history = useHistory();

  const onClickItem = useCallback(() => {
    history.push(`/products/${itemId}`);
    window.scroll(0, 0);
  }, [history, itemId]);

  return (
    <Container onClick={onClickItem}>
        <ItemImg src={images ? images[0] : ""} />
        <ItemSubject>{name}</ItemSubject>
        <PriceList listPrice={listPrice} salesPrice={salesPrice} mode={"list"}/>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 232px;
  height: 346px;
  margin-bottom: 52px;
  margin-right: 20px;
  box-sizing: border-box;
  cursor: pointer;

  :nth-child(4n) {
    margin-right: 0;
  }
`;

const ItemImg = styled.img`
  width: 100%;
  height: 232px;
`;

const ItemSubject = styled.div`
  width: 100%;
  height: 40px;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  box-sizing: border-box;
`;

export default ProductItem;
