import React from "react";
import styled from "styled-components";

function Content({ children }) {
  return <Container>{children}</Container>;
}

const Container = styled.li`
  display: flex;
  height: 26px;
  justify-content: space-between;
  align-items: center;
  position: relative;

  margin-top: 26px;

  :first-child {
    margin-top: 24px;
  }

  p {
    font-size: 20px;
    font-weight: 700;
    margin: 0 8px 0 0;
  }

  input {
    width: 820px;
    height: 100%;
    box-sizing: border-box;
    font-size: 20px;
    border: 1px solid #e0e0e0;
  }

  .essentialSign {
    position: absolute;
    width: 6px;
    top: 14px;
    left: 2px;
  }
`;
export default Content;
