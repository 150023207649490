const mainColor = "#E9345A";
const mainColor2 = "#3352F6";
const gray100 = "#F5F5F5";
const gray200 = "#EEEEEE";
const gray300 = "#E0E0E0";
const gray400 = "#9E9E9E";
const gray500 = "#616161";
const gray600 = "#212121";
const gray000 = "#FFFFFF";
const secondaryColor = "#F6C6D1";
const maincolorContrast = "#C73F5B";
const success = "#5ABB88";
const error = "#F24147";

export {
  mainColor,
  mainColor2,
  gray100,
  gray200,
  gray300,
  gray400,
  gray500,
  gray600,
  gray000,
  secondaryColor,
  maincolorContrast,
  success,
  error,
};
