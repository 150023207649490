import axios from "axios";
import { adminConfig } from "../../config/admin.config";

const API_BASE_URL = adminConfig.apiServer();

export default class SystemSettingApi {
  static getTermsByType = (type) => {
    const apiPath = `${API_BASE_URL}/terms/byType?termsType=${type}`;
    return axios.get(apiPath);
  }

  static getTerms = () => {
    const apiPath = `${API_BASE_URL}/terms`;
    return axios.get(apiPath);
  }
}