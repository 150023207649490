import React, { useContext } from "react";
import styled from "styled-components";

import { error, gray400, gray500 } from "../../styles/fontColors";

import AppInfoContext from "../../contexts/AppInfoContext";

function PriceList({ listPrice, salesPrice, mode, padding = "8px" }) {
  const { appInfo, appDesign } = useContext(AppInfoContext);
  const { priceDisplaySetting } = appDesign;

  let showSubCurrency = false;
  let mainPriceSymbol = "¥";
  let subPriceSymbol = "¥";
  let listPriceAmount = 0;
  let salePriceMainAmount = 0;
  let salePriceSubAmount = 0;

  if (listPrice) {
    showSubCurrency = priceDisplaySetting.subCurrency !== "none" && appInfo.secondaryCurrency !== appInfo.productCurrency;

    /////////////////////////////////////////////////////
    // main price 설정
    listPriceAmount = listPrice.secondaryPrice;       // list price는 main price를 따라간다.
    salePriceMainAmount = salesPrice.secondaryPrice;
    if (priceDisplaySetting?.mainCurrency === "product") {
      mainPriceSymbol = appInfo.productSymbol;
      salePriceMainAmount = salesPrice.originPrice;
      listPriceAmount = listPrice.originPrice;
    } else if (priceDisplaySetting?.mainCurrency === "payment") {
      mainPriceSymbol = appInfo.paymentSymbol;
      salePriceMainAmount = salesPrice.paymentPrice;
      listPriceAmount = listPrice.paymentPrice;
    }
    // main price 설정
    /////////////////////////////////////////////////////

    /////////////////////////////////////////////////////
    // sub price 설정
    subPriceSymbol = "¥";
    salePriceSubAmount = salesPrice.secondaryPrice;
    if (priceDisplaySetting?.subCurrency === "product") {
      subPriceSymbol = appInfo.productSymbol;
      salePriceSubAmount = salesPrice.originPrice;
    } else if (priceDisplaySetting?.subCurrency === "payment") {
      subPriceSymbol = appInfo.paymentSymbol;
      salePriceSubAmount = salesPrice.paymentPrice;
    }
  }

  let showListPrice;
  if (mode === "list") {
    showListPrice = true;
  } else {
    showListPrice = listPriceAmount !== 0 && listPriceAmount != salePriceMainAmount;
  }
  return (
    <PriceWrapper padding={padding}>
      {showListPrice && (
        <ListPrice>
          { (listPriceAmount !== 0 && listPriceAmount != salePriceMainAmount)
            ? mainPriceSymbol + Number(listPriceAmount).toLocaleString("ko-KR")
            : "" }
        </ListPrice>
      )}
      <SalesPricesWrapper>
        <MainPrice>
          {mainPriceSymbol + Number(salePriceMainAmount).toLocaleString("ko-KR")}
        </MainPrice>
        {showSubCurrency && (
          <SubPrice>
            {subPriceSymbol + Number(salePriceSubAmount).toLocaleString("ko-KR")}
          </SubPrice>
        )}
      </SalesPricesWrapper>
    </PriceWrapper>
  );
}

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 24px;
  border-top: 1px solid #f5f5f5;
  padding-top: ${(props) => props.padding};
  padding-bottom: ${(props) => props.padding};
`;

const ListPrice = styled.div`
  font-size: 14px;
  height: 22px;
  text-decoration: line-through;
  color: ${gray400};
  font-weight: 500;
`;

const SalesPricesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 32px;
`;

const MainPrice = styled.div`
  color: ${error};
  font-weight: 700;
`;

const SubPrice = styled.div`
  font-size: 12px;
  color: ${gray500};
  font-weight: 400;
  line-height: 26px;
  margin-left: 4px;
`;

export default PriceList;
