import React, { useState, useEffect, useCallback } from "react";
import intl from "react-intl-universal";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { gray400, gray500, gray600, mainColor2 } from "../../styles/fontColors";
import {message, Spin} from "antd";

import UserApi from "../../components/apis/UserApi";

function FindPasswordMail(props) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loginId, setLoginId] = useState("");
  const [email, setEmail] = useState("");

  const onChangeEmail = useCallback((e) => {
    setEmail(e.target.value);
  }, []);

  const onChangeLoginId = useCallback((e) => {
    setLoginId(e.target.value);
  }, []);

  const onClickLogin = useCallback(() => {
    history.push("/login");
  }, [history]);

  const onClickConfirm = useCallback(() => {
    if (!loginId) {
      message.error(intl.get("common.inputField", {name: intl.get("user.loginId")}));
      return;
    }
    if (!email) {
      message.error(intl.get("common.inputField", {name: intl.get("user.email")}));
      return;
    }

    setLoading(true);
    UserApi.findPassword(loginId, email).then(() => {
      alert(intl.get("user.message.sentPasswordMail"));
      window.location = "/login";
    }).catch(error => {
      if (error.response && error.response.status === 500 && error.response.data) {
        message.error(error.response.data);
      } else {
        message.error(intl.get("common.message.contactAdmin"));
      }
    }).finally(() => {
      setLoading(false);
    });
  }, [loginId, email]);

  return (
    <Spin spinning={loading}>
      <Container>
        <span>{intl.get("header.findPassword")}</span>
        <LoginInput
          onChange={onChangeLoginId}
          value={loginId || ""}
          placeholder={intl.get("user.loginId")}
          type="text"
        />
        <LoginInput
          onChange={onChangeEmail}
          value={email || ""}
          placeholder={intl.get("user.email")}
          type="text"
        />

        <ConfirmBtn onClick={onClickConfirm}>{intl.get("common.button.ok")}</ConfirmBtn>
        <GoLogin onClick={onClickLogin}>{intl.get("header.login")}</GoLogin>
      </Container>
    </Spin>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  span {
    color: ${gray600};
    font-size: 24px;
    font-weight: 700;
    margin: 18px auto 34px;
  }
`;

const LoginInput = styled.input`
  width: 368px;
  height: 45px;
  border: 1px solid #9e9e9e;
  border-radius: 2px;
  margin-bottom: 18px;
  font-size: 16px;
  color: ${gray500};
  box-sizing: border-box;
  padding: 10px 16px;
`;

const GetCodeBtn = styled.div`
  position: absolute;
  right: 16px;
  bottom: 30px;
  z-index: 9;
  font-size: 16px;
  font-weight: 700;
  color: ${mainColor2};
  cursor: pointer;
`;

const ConfirmBtn = styled.button`
  width: 368px;
  height: 45px;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 700;
  margin-top: 6px;
  background-color: ${mainColor2};
  color: white;
  border: none;
`;

const GoLogin = styled.div`
  cursor: pointer;
  color: ${gray400};
  margin-top: 16px;
  font-size: 14px;
`;

export default FindPasswordMail;
