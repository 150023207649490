import React, { useCallback, useEffect, useReducer, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ReactModal from "react-modal";
import intl from "react-intl-universal";
import Cookies from "universal-cookie";
import urlParse from "url-parse";
import moment from "moment";
import "antd/dist/antd.css";

import "./App.less";

import AppLayout from "./components/layout/AppLayout";
import ApplicationApi from "./components/apis/ApplicationApi";
import { getCookie, setCookie } from "./cookie/setCookie";
import UserApi from "./components/apis/UserApi";
import messageZhCN from "./locales/zh-CN";
import messageKoKR from "./locales/ko-KR";
import { adminConfig } from "./config/admin.config";
import Favicon from "./assets/favicon.png";

import {
  initialState as appInitial,
  reducer as appReducer,
} from "./contexts/AppInfoContext";
import AppInfoContext from "./contexts/AppInfoContext";
import axios from "axios";

ReactModal.setAppElement("#root");

const cookies = new Cookies();

const localeMessages = {
  "zh-CN": messageZhCN,
  "ko-KR": messageKoKR,
};

function App() {
  const [appState, appDispatch] = useReducer(appReducer, appInitial);
  const [loadedAppInfo, setLoadedAppInfo] = useState(false);

  const appIdCookie = getCookie("appId");
  const changeAppInfo = useCallback((value) => {
    appDispatch({
      type: "CHANGE_INFO",
      appInfo: value.app,
      appDesign: value.appDesign,
      detailPageSettings: value.productPage.settings,
      systemSettings: value.systemSettings,
    });
  }, []);

  const changeLocale = (locale, reloadPage = false) => {
    const url = urlParse(adminConfig.adminWebServer());
    cookies.set("locale", locale, { path: "/", domain: url.hostname });
    if (locale === "zh-CN") {
      moment.locale("zh-cn");
    } else {
      moment.locale("ko");
    }

    intl.init({
      currentLocale: locale,
      locales: localeMessages,
    });

    if (reloadPage) {
      window.location.href = "/";
      window.location.reload();
    }
  };

  useEffect(() => {
    getApplication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let locale = cookies.get("locale");
    if (!locale) {
      const lang = navigator.language || navigator.userLanguage;
      // en-US, zh-CN, zh, ko
      if (lang.startsWith("zh")) {
        locale = "zh-CN";
      } else {
        locale = "ko-KR";
      }
    }
    changeLocale(locale, false);
  }, []);

  // 도메인 이름을 이용하여 ApplicationID를 가져온 후
  // TODO cookie에 값이 없는 경우, 다시 이 로직이 호출되도록 처리 필요
  const getApplication = () => {
    const host = window.location.host;
    ApplicationApi.getCurrent(host)
      .then((res) => {
        const app = res.data;

        document.title = app?.app?.appTitle ? app?.app?.appTitle : "";
        let favicon = Favicon;
        if (app?.appDesign?.appLogoImage) {
          favicon = app?.appDesign?.appLogoImage;
        }
        axios.defaults.headers["X-ApplicationId"] = app.app.id;
        document.getElementById("favicon").href = favicon;
        changeAppInfo(app);
        setCookie("appId", app.app.id);
        setLoadedAppInfo(true);
        getCurrentUser();
      })
      .catch((e) => {});
  };

  const getCurrentUser = () => {
    const host = window.location.host;
    UserApi.getCurrent(host)
      .then((res) => {
        setCookie("userName", res.data.name);
        setCookie("userId", res.data.id);
        // setCookie("pNo", res.data.phone);
      })
      .catch((e) => {});
  };

  if (!loadedAppInfo) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <BrowserRouter>
        <Switch>
          <AppInfoContext.Provider value={appState}>
            {loadedAppInfo && <Route path={"/"} component={AppLayout} />}
          </AppInfoContext.Provider>
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
