import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { adminConfig } from "./config/admin.config";
import { AxiosConfigure } from "./config/axios.configure";
import "./styles/index.less";

if (adminConfig.authentication.used) {
  AxiosConfigure.configAuthInterceptor();
}

AxiosConfigure.configServerNetworkErrorInterceptor();
AxiosConfigure.configServerErrorInterceptor();
AxiosConfigure.configLoadingInterceptor();

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
