import { createContext } from "react";

export const initialState = {
  appInfo: null,
  detailPageSettings: null,
  appDesign: null,
  systemSettings: null,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_INFO":
      return {
        appInfo: action.appInfo,
        appDesign: action.appDesign,
        detailPageSettings: action.detailPageSettings,
        systemSettings: action.systemSettings,
      };
    default:
      return state;
  }
};

const AppInfoContext = createContext(initialState);

export default AppInfoContext;
