import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

function GoHomeBtn({
  children,
  borderColor = "",
  backgroundColor = "#3352f6",
  fontColor = "white",
  location= "/"
}) {
  const history = useHistory();

  const onClickGoHomeBtn = useCallback(() => {
    history.push(location);
  }, [history]);

  return (
    <HomeBtn
      onClick={onClickGoHomeBtn}
      borderColor={borderColor}
      backgroundColor={backgroundColor}
      fontColor={fontColor}
    >
      {children}
    </HomeBtn>
  );
}

const HomeBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 56px;
  background-color: ${(props) => props.backgroundColor};
  font-size: 20px;
  font-weight: 700;
  color: ${(props) => props.fontColor};
  box-sizing: border-box;
  border: 1px solid white;
  border-color: ${(props) => props.borderColor};
  border-radius: 4px;
  cursor: pointer;
`;

export default GoHomeBtn;
