import React, { useCallback, useState } from "react";
import intl from "react-intl-universal";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import {message} from "antd";
import { gray600, mainColor2 } from "../../styles/fontColors";
import * as queryString from "query-string";

import UserApi from "../../components/apis/UserApi";

function ResetPassword(props) {
  const urlParams = queryString.parse(window.location.search)

  const history = useHistory();
  const [password, setPassword] = useState("");
  const [confirmingPassword, setConfirmingPassword] = useState("");

  const onChangePassword = useCallback((e) => {
    setPassword(e.target.value);
  }, []);

  const onChangeConfirmingPassword = useCallback((e) => {
    setConfirmingPassword(e.target.value);
  }, []);

  const onClickChangePassword = useCallback(() => {
    if (!password) {
      message.error(intl.get("common.inputField", {name: intl.get("user.password")}));
      return;
    }

    if (password.length < 8) {
      message.error(intl.get("user.message.shortPassword"));
      return;
    }

    if (password !== confirmingPassword) {
      message.error(intl.get("user.message.diffPassword"));
      return;
    }

    UserApi.changePassword(urlParams.token, password).then(() => {
      alert(intl.get("user.message.password.changed"));
      history.push("/");
    }).catch((err) => {
      if (err.response && err.response.status === 500 && err.response.data) {
        message.error(err.response.data);
      } else {
        message.error(intl.get("common.message.contactAdmin"));
      }
    });
  }, [password, confirmingPassword, history, urlParams.token]);

  return (
    <Container>
      <span>{intl.get("header.resetPassword")}</span>
      <LoginInput
        value={password || ""}
        onChange={onChangePassword}
        placeholder={intl.get("user.password")}
        type="password"
      />
      <LoginInput
        value={confirmingPassword || ""}
        onChange={onChangeConfirmingPassword}
        placeholder={intl.get("user.confirmingPassword")}
        type="password"
      />
      <LoginConfirmBtn onClick={onClickChangePassword}>{intl.get("common.button.ok")}</LoginConfirmBtn>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  span {
    color: ${gray600};
    font-size: 24px;
    font-weight: 700;
    margin: 18px auto 34px;
  }
`;

const LoginInput = styled.input`
  width: 368px;
  height: 45px;
  border: 1px solid #9e9e9e;
  border-radius: 2px;
  margin-bottom: 18px;
  font-size: 16px;
  color: ${gray600};
  box-sizing: border-box;
  padding: 10px 16px;
`;

const LoginConfirmBtn = styled.button`
  width: 368px;
  height: 45px;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 700;
  margin-top: 6px;
  background-color: ${mainColor2};
  color: white;
  border: none;
  cursor: pointer;
`;

export default ResetPassword;
