import React, { useState } from "react";
import { Spin, Button, Steps, message } from "antd";
import OrderApi from "../apis/OrderApi";
import intl from "react-intl-universal";
import NoDataImg from "../../assets/no_data.png";

const { Step } = Steps;

const DeliveryStatus = (props) => {
  const [updating, setUpdating] = useState(false);
  const [deliverySteps, setDeliverySteps] = useState([]);
  const [showDeliverySteps, setShowDeliverySteps] = useState(false);

  const showDeliveryStatus = () => {
    setUpdating(true);
    OrderApi.getDeliveryStatus(props.order.id)
      .then((res) => {
        const deliveryStatus = res.data;
        if (deliveryStatus.currentState === "错误") {
          message.error(
            "배송정보 조회 중 오류가 발생했습니다. 관리자에게 문의하세요."
          );
          return;
        }
        let steps = [];
        if (deliveryStatus.data) {
          steps = deliveryStatus.data.map((item) => {
            return {
              text: item.time,
              desc: item.context,
            };
          });
          setDeliverySteps(steps);
        }
        setShowDeliverySteps(true);
      })
      .catch((e) => {})
      .finally(() => {
        setUpdating(false);
      });
  };

  const { order } = props;
  return (
    <Spin spinning={updating}>
      <div>
        { (order && order.orderDelivery && order.orderDelivery.deliveryNo) ? (
          <div style={{ display: "flex", marginTop: 20, marginBottom: 20 }}>
            <div>
              <h3>
                {intl.get("order.deliveryNo")}:{" "}
                {order.orderDelivery.deliveryNo} ({order.orderDelivery.deliveryCompany})
              </h3>
            </div>
            <div style={{ marginLeft: "auto" }}>
              <Button type="primary" size="small" onClick={showDeliveryStatus}>
                {intl.get("order.delivery.tracking")}
              </Button>
            </div>
          </div>
        ): (
          <div style={{ display: "flex", justifyContent: "center", marginTop: 100, marginBottom: 20 }}>
            <img src={NoDataImg}/>
          </div>
        )}
        <div>
          {showDeliverySteps && (
            <div>
              <div>
                {deliverySteps.length > 0 && (
                  <Steps
                    size={"small"}
                    progressDot
                    current={0}
                    direction="vertical"
                  >
                    {deliverySteps.map((eachStep, index) => {
                      return (
                        <Step
                          key={index}
                          title={eachStep.text}
                          description={eachStep.desc}
                        />
                      );
                    })}
                  </Steps>
                )}

                {deliverySteps.length === 0 && (
                  <div style={{ display: "flex", justifyContent: "center", marginTop: 100, marginBottom: 20 }}>
                    <img src={NoDataImg}/>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Spin>
  );
};

export default DeliveryStatus;
