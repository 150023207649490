import React, { useState, useCallback } from "react";
import intl from "react-intl-universal";
import styled from "styled-components";
import {Link, useHistory} from "react-router-dom";
import {message, Modal, Spin, Form, Input, Checkbox} from "antd";

import { gray400, gray500, gray600, mainColor2 } from "../../styles/fontColors";

import { AuthService } from "../../auth/auth.service";

function Signup(props) {
  const history = useHistory();
  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);

  const checkAll = (e) => {
    const user = form.getFieldsValue();
    user.agreeTerms = e.target.checked;
    user.agreePrivacy = e.target.checked;
    user.agreeReceiveMail = e.target.checked;
    form.setFieldsValue(user);
  }

  const registerUser = async (values) => {
    if (values.loginId.length < 6) {
      message.error(intl.get("user.message.shortLoginId"));
      return;
    }

    if (values.password.length < 8) {
      message.error(intl.get("user.message.shortPassword"));
      return;
    }

    if (!values.agreeTerms) {
      message.error(intl.get("user.message.agree1"));
      return;
    }
    if (!values.agreePrivacy) {
      message.error(intl.get("user.message.agree2"));
      return;
    }

    const user = JSON.parse(JSON.stringify(values));
    setSaving(true);
    AuthService.register(user).then((res) => {
      message.info(intl.get("user.message.registered"));
        history.push("/");
      })
      .catch((err) => {
        console.log(">>Signup.error:", err.response);
        let errorMessage = intl.get("common.message.contactAdmin");
        if (err.response && err.response.status) {
          if (err.response.status === 500) {
            errorMessage = err.response.data;
          }
        }
        message.error(errorMessage);
      }).finally(() => {
        setSaving(false);
    });
  };

  const onClickLogin = useCallback(() => {
    history.push("/login");
  }, [history]);

  const inputStyle = {
    width: "330px",
    height: "44px",
    fontSize: "16px",
  };

  return (
    <Spin spinning={saving}>
      <div className="login-page">
        <FormWrapper className={"contents"}>
          <Title>{intl.get("header.signup")}</Title>
          <FormBox className={"form-box"}>
            <Form form={form} onFinish={registerUser}>
              <FormItemWrapper className={"form-item"}>
                <FormLabel><span style={{color: "#ec5c57", marginRight: 4}}>*</span>{intl.get("user.name")}</FormLabel>
                <Form.Item name="name"
                           rules={[{required: true, message: intl.get("common.inputField", {name: intl.get("user.name")})}]}>
                  <Input style={inputStyle}/>
                </Form.Item>
              </FormItemWrapper>
              <FormItemWrapper>
                <FormLabel><span style={{color: "#ec5c57", marginRight: 4}}>*</span>{intl.get("user.loginId")}</FormLabel>
                <Form.Item name="loginId"
                           rules={[{required: true, message: intl.get("common.inputField", {name: intl.get("user.loginId")})}]}>
                  <Input style={inputStyle}/>
                </Form.Item>
              </FormItemWrapper>
              <FormItemWrapper>
                <FormLabel><span style={{color: "#ec5c57", marginRight: 4}}>*</span>{intl.get("user.password")}</FormLabel>
                <Form.Item name="password"
                           rules={[{required: true, message: intl.get("common.inputField", {name: intl.get("user.password")})}]}>
                  <Input.Password style={inputStyle}/>
                </Form.Item>
              </FormItemWrapper>
              <FormItemWrapper>
                <FormLabel><span style={{color: "#ec5c57", marginRight: 4}}>*</span>{intl.get("user.confirmingPassword")}</FormLabel>
                <Form.Item name="confirm" dependencies={['password']} hasFeedback
                           rules={[{required: true, message: intl.get("common.inputField", {name: intl.get("user.confirmingPassword")})},
                             ({getFieldValue}) => ({
                               validator(rule, value) {
                                 if (!value || getFieldValue('password') === value) {
                                   return Promise.resolve();
                                 }

                                 return Promise.reject(intl.get("user.message.diffPassword"));
                               },
                             }),
                           ]}
                >
                  <Input.Password style={inputStyle}/>
                </Form.Item>
              </FormItemWrapper>
              <FormItemWrapper>
                <FormLabel><span style={{color: "#ec5c57", marginRight: 4}}>*</span>{intl.get("user.phone")}</FormLabel>
                <Form.Item name="phone"
                           rules={[{required: true, message: intl.get("common.inputField", {name: intl.get("user.phone")})}]}>
                  <Input style={inputStyle}/>
                </Form.Item>
              </FormItemWrapper>
              <FormItemWrapper>
                <FormLabel><span style={{color: "#ec5c57", marginRight: 4}}>*</span>{intl.get("user.email")}</FormLabel>
                <Form.Item name="email"
                           rules={[{required: true, message: intl.get("common.inputField", {name: intl.get("user.email")})}]}>
                  <Input style={inputStyle}/>
                </Form.Item>
              </FormItemWrapper>
              <FormItemWrapper>
                <div className={"checkbox-item-all"}>
                  <Checkbox className={"checkbox"} onChange={checkAll}><span className={"checkbox-text bold"} style={{marginLeft: 6}}>{intl.get("terms.selectAll")}</span></Checkbox>
                </div>
                <Line/>
                <div className={"checkbox-item"}>
                  <Form.Item name={"agreeTerms"} valuePropName="checked" noStyle>
                    <Checkbox className={"checkbox"}/>
                  </Form.Item>
                  <span className={"checkbox-text gray"}>{intl.get("register.must")}&nbsp;</span>
                  <span className={"checkbox-text"}><Link to={"/terms/user"} target="_blank">{intl.get("terms.termsType.user")}</Link></span>
                  <span className={"checkbox-text gray"}>&nbsp;{intl.get("register.agree")}</span>
                </div>
                <div className={"checkbox-item"}>
                  <Form.Item name={"agreePrivacy"} valuePropName="checked" noStyle>
                    <Checkbox className={"checkbox"}/>
                  </Form.Item>
                  <span className={"checkbox-text gray"}>{intl.get("register.must")}&nbsp;</span>
                  <span className={"checkbox-text"}><Link to={"/terms/privacy"} target="_blank">{intl.get("terms.termsType.privacy")}</Link></span>
                  <span className={"checkbox-text gray"}>&nbsp;{intl.get("register.agree")}</span>
                </div>
              </FormItemWrapper>
            </Form>
            <ButtonWrapper>
              <ConfirmBtn onClick={() => form.submit()}>{intl.get("common.button.ok")}</ConfirmBtn>
              <GoLogin onClick={onClickLogin}>{intl.get("header.login")}</GoLogin>
            </ButtonWrapper>
          </FormBox>
        </FormWrapper>
      </div>
    </Spin>
  );
}

const ConfirmBtn = styled.button`
  width: 368px;
  height: 45px;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 700;
  margin-top: 6px;
  background-color: ${mainColor2};
  color: white;
  border: none;
  cursor: pointer;
`;

const GoLogin = styled.div`
  cursor: pointer;
  color: ${gray400};
  margin-top: 16px;
  font-size: 14px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
  //height: 24px;
  margin-top: 24px;
  font-size: 24px;
  font-weight: bold;
  font-family: NotoSans;
  color: #000000;
`;

const TitleDesc = styled.div`
  margin: 4px 0 0;
  font-size: 16px;
  color: #616161;
  font-family: NotoSans;
`;

const FormBox = styled.div`
  width: 418px;
  margin-top: 20px;
  padding: 0 44px ;
  //border-radius: 2px;
  //border: solid 2px #e0e0e0;
  //background-color: #fff;
`;

const FormItemWrapper = styled.div`
  text-align: left;
  margin-top: 16px;
`;

const FormLabel = styled.div`
  font-family: NotoSans-Display;
  height: 19px;
  font-size: 14px;
  margin-bottom: 6px;
`;

const FormInput = styled.div`
  width: 330px;
  height: 44px;
  font-size: 16px;
`;

const Line = styled.div`
  width: 330px;
  height: 1px;
  margin: 9px 0 10px 0px;
  background-color: #e0e0e0;
`;

const ButtonWrapper =  styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 28px
`;
export default Signup;
