import React, { useContext } from "react";
import styled from "styled-components";
import { gray500 } from "../../styles/fontColors";

import AppInfoContext from "../../contexts/AppInfoContext";

function OrderSkuPrice({ salesPrice, quantity }) {
  const { appInfo, appDesign } = useContext(AppInfoContext);
  const { priceDisplaySetting } = appDesign;

  let showSubCurrency = false;
  let mainPriceSymbol = "¥";
  let subPriceSymbol = "¥";
  let salePriceMainAmount = 0;
  let salePriceSubAmount = 0;

  if (salesPrice) {
    showSubCurrency = priceDisplaySetting.subCurrency !== "none" && appInfo.secondaryCurrency !== appInfo.productCurrency;

    /////////////////////////////////////////////////////
    // main price 설정
    salePriceMainAmount = salesPrice.secondaryPrice;
    if (priceDisplaySetting?.mainCurrency === "product") {
      mainPriceSymbol = appInfo.productSymbol;
      salePriceMainAmount = salesPrice.originPrice;
    } else if (priceDisplaySetting?.mainCurrency === "payment") {
      mainPriceSymbol = appInfo.paymentSymbol;
      salePriceMainAmount = salesPrice.paymentPrice;
    }
    // main price 설정
    /////////////////////////////////////////////////////

    /////////////////////////////////////////////////////
    // sub price 설정
    subPriceSymbol = "¥";
    salePriceSubAmount = salesPrice.secondaryPrice;
    if (priceDisplaySetting?.subCurrency === "product") {
      subPriceSymbol = appInfo.productSymbol;
      salePriceSubAmount = salesPrice.originPrice;
    } else if (priceDisplaySetting?.subCurrency === "payment") {
      subPriceSymbol = appInfo.paymentSymbol;
      salePriceSubAmount = salesPrice.paymentPrice;
    }
  }

  return (
    <SkuPriceWrapper>
      <PriceWrapper>
        <div>
          {mainPriceSymbol}
          {Number(salePriceMainAmount).toLocaleString("ko-KR")}
        </div>
        {showSubCurrency && (
          <SubPrice className="priceSub">
            {subPriceSymbol}
            {Number(salePriceSubAmount).toLocaleString("kr-KR")}
          </SubPrice>
        )}
      </PriceWrapper>
      <div>{quantity}</div>
    </SkuPriceWrapper>
  );
}

const SkuPriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 700;
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-size: 20px;
  font-weight: 700;
`;

const SubPrice = styled.div`
  font-size: 12px;
  color: ${gray500};
  font-weight: 400;
  line-height: 26px;
  margin-left: 4px;
`;

export default OrderSkuPrice;
