import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import styled from "styled-components";

import OrderApi from "../apis/OrderApi";
import { gray100, gray300 } from "../../styles/fontColors";
import DeliveryStatus from "./DeliveryStatus";

const { Content } = Layout;

const OrderDeliveryPopup = (props) => {
  const [order, setOrder] = useState({
    orderSkus: [],
    shippingAddress: {},
  });

  useEffect(() => {
    //주문의 orderDeclare 정보가 없으면 생성한다.
    //createOrderDeclare는 없으면 생성하면 있으면 처리하지 않는다.
    OrderApi.getOrder(props.id)
      .then((res) => {
        const order = res.data;
        setOrder(order);
      })
      .catch();
  }, [props.id]);

  const loadOrderAndSet = () => {
    OrderApi.getOrder(props.id)
      .then((res) => {
        const order = res.data;
        setOrder(order);
        props.setOrder(order);
      })
      .catch();
  };

  return (
    <Container>
      <Layout style={{ width: "100%", backgroundColor: "white" }}>
        <Content>
          <div>
            <DeliveryStatus
              order={order}
              application={props.application}
            />
          </div>
        </Content>
      </Layout>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  overflow: auto;
  top: 20px;
  &::-webkit-scrollbar {
    background-color: ${gray100};
  }

  &::-webkit-scrollbar-track {
    width: 15px;
    background-color: ${gray100};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${gray300};
    background-clip: padding-box;
    border: solid transparent;
    border-width: 2px 3px;
    border-radius: 8px;
  }
`;

export default OrderDeliveryPopup;
