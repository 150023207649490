import React, { useCallback, useEffect, useState } from "react";
import intl from "react-intl-universal";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { gray600, mainColor2 } from "../../styles/fontColors";

import LogoImg from "../../assets/logo.png";
import { AuthService } from "../../auth/auth.service";
import SmsMessageApi from "../../components/apis/SmsMessageApi";

const SMS_TIMEOUT = 120;

function LoginWithSMS(props) {
  // const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [phone, setPhone] = useState("");
  const [smsCode, setSmsCode] = useState("");
  const [smsBizId, setSmsBizId] = useState("");
  const [sentSmsCode, setSentSmsCode] = useState(false);

  // 인증 남아 있는 시간
  const [remainSecond, setRemainSecond] = useState(SMS_TIMEOUT);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    return () => {
      if (timer != null) {
        window.clearInterval(timer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickSignup = useCallback(() => {
    props.handleModal("signup", true);
  }, [props]);

  const onChangePhone = useCallback((e) => {
    setPhone(e.target.value);
  }, []);

  const onChangeSmsCode = useCallback((e) => {
    setSmsCode(e.target.value);
  }, []);

  const sendSmsCode = () => {
    if (!phone) {
      alert(
        intl.get("common.inputField", {
          name: intl.get("order.phone"),
        })
      );
      return;
    }

    SmsMessageApi.sendSmsCode(phone, "","login")
      .then((res) => {
        setSentSmsCode(true);
        setRemainSecond(SMS_TIMEOUT);
        setSmsBizId(res.data);
        const timer = window.setInterval(() => {
          let remainSecond = 0;
          setRemainSecond((previousValue) => {
            remainSecond = previousValue - 1;
            if (remainSecond <= 0) {
              return 0;
            }
            return previousValue - 1;
          });
          if (remainSecond <= 0) {
            setSentSmsCode(false);
            setSmsBizId("");
            setSmsCode("");
            window.clearInterval(timer);
            setTimer(null);
          }
        }, 1000);
        setTimer(timer);
        alert(
          intl.get("common.message.sent-code", {
            name: phone,
          })
        );
      })
      .catch((err) => {
        const errorMessage =
          err.response && err.response.data
            ? err.response.data
            : intl.get("order.clearance.table.errorMessage");
        console.log("sendSmsCode error:", err.response ? err.response : err);
        alert(errorMessage);
      });
  };

  const onClickLogin = useCallback(() => {
    if (!smsBizId) {
      alert(intl.get("common.message.get-code"));
      return;
    }

    if (!smsCode) {
      alert(
        intl.get("common.inputField", {
          name: intl.get("common.veryfication-code"),
        })
      );
      return;
    }

    AuthService.login(smsCode, smsBizId)
      .then(() => {
        // document.location.reload();
        history.push("");
      })
      .catch((err) => {
        console.log(">>login.error:", err.response);
        const isSmsError =
          err.response &&
          err.response.data &&
          err.response.data &&
          err.response.data.errorCode;
        if (isSmsError) {
          alert(err.response.data.message);
          if (err.response.data.errorCode === 999) {
            // TODO close 처리
          }
          // TODO errorCode 상황에 따른 화면 처리
        } else {
          alert(intl.get("common.message.contactAdmin"));
        }
      });

    props.handleModal("", false);
  }, [smsBizId, smsCode, props, history]);

  return (
    <Container>
      <img src={LogoImg} alt="logo" style={{ margin: "10px 0 0 0" }} />
      <span>{intl.get("header.login")}</span>
      <LoginInput
        value={phone}
        onChange={onChangePhone}
        placeholder={intl.get("order.phone")}
        disabled={sentSmsCode}
        type="number"
      ></LoginInput>

      <div style={{ position: "relative" }}>
        <LoginInput
          onChange={onChangeSmsCode}
          placeholder={intl.get("common.veryfication-code")}
          value={smsCode}
        />
        {!sentSmsCode && (
          <GetCodeBtn onClick={sendSmsCode}>
            {intl.get("common.button.verify")}
          </GetCodeBtn>
        )}
      </div>

      {sentSmsCode && (
        <div>
          {remainSecond > 0
            ? remainSecond + intl.get("common.message.timer")
            : intl.get("common.message.timer-over")}
        </div>
      )}
      <LoginConfirmBtn onClick={onClickLogin}>
        {intl.get("common.button.ok")}
      </LoginConfirmBtn>
      <GoSignup onClick={onClickSignup}>{intl.get("header.signup")}</GoSignup>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  span {
    color: ${gray600};
    font-size: 24px;
    font-weight: 700;
    margin: 18px auto 34px;
  }
`;

const LoginInput = styled.input`
  width: 368px;
  height: 45px;
  border: 1px solid #9e9e9e;
  border-radius: 2px;
  margin-bottom: 18px;
  font-size: 16px;
  color: ${gray600};
  box-sizing: border-box;
  padding: 10px 16px;
`;

const GetCodeBtn = styled.div`
  position: absolute;
  right: 16px;
  bottom: 30px;
  z-index: 9;
  font-size: 16px;
  font-weight: 700;
  color: ${mainColor2};
  cursor: pointer;
`;

const LoginConfirmBtn = styled.button`
  width: 368px;
  height: 45px;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 700;
  margin-top: 6px;
  background-color: ${mainColor2};
  color: white;
  border: none;
`;

const GoSignup = styled.div`
  cursor: pointer;
  color: #9e9e9e;
  margin-top: 16px;
  font-size: 14px;
`;

export default LoginWithSMS;
