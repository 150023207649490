import axios from "axios";
import { adminConfig } from "../../config/admin.config";

const API_BASE_URL = adminConfig.apiServer();

export default class SmsMessageApi {
  static sendSmsCode = (phone, loginId, template) => {
    const apiPath = `${API_BASE_URL}/sms/send?loginId=${loginId}&phone=${phone}&template=${template}`;
    return axios.get(apiPath);
  }
  static verifySmsCode = (smsCode, bizId, template) => {
    const params = {
      code: smsCode,
      bizId: bizId,
      template: template,
    };
    const apiPath = `${API_BASE_URL}/sms/verify`;
    return axios.put(apiPath, params);
  }
}