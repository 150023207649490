import React from "react";
import styled from "styled-components";

import { gray100, mainColor2 } from "../../styles/fontColors";

import LoaderCircle from "../../components/Loader/LoaderCirlce";

function ProductSkeleton() {
  return (
    <>
      <ItemInfo>
        <ItemImage>
          <LoaderCircle />
        </ItemImage>
        <ItemSummary>
          <div className="subject"></div>
          <div className="subject"></div>
          <div className="subject"></div>
          <BuyBtn />
        </ItemSummary>
      </ItemInfo>
      <div style={{ width: "100%", height: "800px" }}></div>
    </>
  );
}

const ItemInfo = styled.div`
  display: flex;
  width: 100%;
  min-height: 484px;

  .subject {
    height: 60px;
    width: 100%;
    margin-bottom: 25px;
    font-size: 20px;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;

    background-color: blue;
  }

  @keyframes loading {
    to {
      background-position: 315px 0, 0 0, 0 190px, 50px 195px;
    }
  }
`;

const ItemSummary = styled.div`
  position: relative;
  margin: 5px 0px 5px 15px;
  box-sizing: border-box;
  width: 484px;
  height: 484px;

  .subject {
    height: 50px;
    background-color: ${gray100};
    background: linear-gradient(0.1turn, transparent, #fff, transparent),
      linear-gradient(#fbfbfb, #eee), linear-gradient(#fbfbfb, #eee);
    background-repeat: no-repeat;
    animation: loading 1.5s infinite;
    border-radius: 5px;
  }
`;

const ItemImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 484px;
  height: 484px;
  /* background-color: #eaeaea;
  background-color: #dddbdd;
  background: linear-gradient(0.25turn, transparent, #fff, transparent),
    linear-gradient(#fbfbfb, #eee), linear-gradient(#eee, #eee);
  background-repeat: no-repeat;
  animation: loading 1.5s infinite;
  border-radius: 10px; */
`;

const BuyBtn = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 54px;
  font-size: 16px;
  font-weight: 700;
  background-color: ${mainColor2};
  color: white;
  cursor: pointer;
  margin: 16px 0;
  bottom: 0;

  background-color: #eaeaea;
  background-color: #dddbdd;
  background: linear-gradient(0.25turn, transparent, #fff, transparent),
    linear-gradient(#fbfbfb, #eee), linear-gradient(#fbfbfb, #eee);
  background-repeat: no-repeat;
  animation: loading 1.5s infinite;
  border-radius: 5px;
`;

export default ProductSkeleton;
