import React from "react";
import styled from "styled-components";

import LoaderCircle from "../Loader/LoaderCirlce";

function ProductItemSkeleton() {
  return (
    <Container>
      <ItemImg>
        {/*<LoaderCircle />*/}
      </ItemImg>
      <ItemSubject>
        <ItemTextShort/>
      </ItemSubject>
      <ItemSubject>
        <ItemText/>
      </ItemSubject>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 232px;
  height: 346px;
  margin-bottom: 52px;
  margin-right: 20px;
  box-sizing: border-box;

  :nth-child(4n) {
    margin-right: 0;
  }

  @keyframes loading {
    to {
      background-position: 315px 0, 0 0, 0 190px, 50px 195px;
    }
  }
`;

const ItemImg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 232px;
  background-color: rgba(190,190,190,.2);
  /* background-color: #eaeaea;
  background-color: #dddbdd;

  background: linear-gradient(0.25turn, transparent, #fff, transparent),
    linear-gradient(#eee, #eee), linear-gradient(#eee, #eee);
  background-repeat: no-repeat;
  animation: loading 1.5s infinite; */
`;

const ItemSubject = styled.div`
  width: 100%;
  height: 40px;
  background-color: #ffffff;
`;

const ItemText = styled.div`
  width: 100%;
  height: 36px;
  background-color: #eaeaea;
`;

const ItemTextShort = styled.div`
  width: 100px;
  height: 36px;
  background-color: #eaeaea;
`;

export default ProductItemSkeleton;
