const setPaymentCurrency = (paymentCurrency) => {
  switch (paymentCurrency) {
    case "USD":
      return "$";
    case "CNY":
      return "¥";
    case "KRW":
      return "₩";
    default:
      return;
  }
};

export default setPaymentCurrency;
