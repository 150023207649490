import React, {useCallback, useContext, useEffect, useState} from "react";
import styled from "styled-components";
import intl from "react-intl-universal";
import moment from "moment";
import Util from "../../utils/Util";
import AppInfoContext from "../../contexts/AppInfoContext";
import {message} from "antd";

import { error, gray400, gray500, gray600, mainColor2 } from "../../styles/fontColors";

import OrderApi from "../../components/apis/OrderApi";
import Subject from "../../components/list/Subject";
import MypagePrice from "../../components/priceList/MypagePrice";
import { useHistory, Link } from "react-router-dom";
import ChangeShippingAddressModal from "./ChangeShippingAddressModal";

function OrderDetail({ match, handleModal }) {
  const { appInfo } = useContext(AppInfoContext);
  const history = useHistory();
  const orderId = match.params.orderNo;

  const [order, setOrder] = useState();
  const [visibleAddressModal, setVisibleAddressModal] = useState(false);

  useEffect(() => {
    loadOrder();
  }, [orderId]);

  const loadOrder = () => {
    OrderApi.getOrder(orderId)
      .then((res) => {
        setOrder(res.data);
      }).catch((err) => {
        alert(intl.get("common.message.notFound"));
      });
  };

  const onClickDeliveryBtn = useCallback(
    (id) => {
      handleModal(
        "delivery",
        false,
        id,
        "",
        "rgba(0, 0, 0, 0.5)",
        "700px",
        "580px"
      );
    },
    [handleModal]
  );

  const getOrderAddress = () => {
    if (!order?.shippingAddress) {
      return "";
    }
    if (Object.keys(order?.shippingAddress).length === 0) {
      return "";
    }
    if (order.shippingAddress.countryCode === "82") {
      return order.shippingAddress.addressDetail;
    } else {
      return order.shippingAddress.addressProvince +
        "  " +
        order.shippingAddress.addressCity +
        "  " +
        order.shippingAddress.addressDistrict +
        "  " +
        order.shippingAddress.addressCode +
        "  " +
        order.shippingAddress.addressDetail;
    }
  };

  const onClickCancelOrder = () => {
    if (!window.confirm(intl.get("order.cancel.confirm"))) {
      return;
    }
    OrderApi.cancelOrder(order.id).then(res => {
      message.info(intl.get("order.cancel.saved"));
      loadOrder();
    }).catch((err) => {
      message.error(intl.get("common.message.contactAdmin"));
    });
  };

  const saveShippingAddress = (shippingAddress) => {
    for (let key in shippingAddress) {
      if (key === "countryCode" || key === "userComments") continue;
      if (shippingAddress[key] === "") {
        message.error(intl.get("order.delivery.input"));
        return;
      }
    }
    OrderApi.saveShippingAddress(order.id, shippingAddress).then(res => {
      message.info(intl.get("common.message.saved"));
      setVisibleAddressModal(false);
      loadOrder();
    }).catch((err) => {
      message.error(intl.get("common.message.contactAdmin"));
    });
  };

  let canCancel = order?.currentStatus === "wait_pay" ||
    order?.currentStatus === "paid" ||
    order?.currentStatus === "pay_prepared";

  return (
    <>
      <Container>
        <ul>
          <Subject fontSize="28px" paddingBottom="22px">{intl.get("order.orderInfo")}</Subject>
          <ul>
            <Content fontSize="20px">
              <p>{intl.get("order.orderNo")}</p>
              <ContentDetail fontSize="20px">{order?.orderNo}</ContentDetail>
            </Content>
            <Content fontSize="20px">
              <p>{intl.get("order.orderDate")}</p>
              <ContentDetail fontSize="20px">{order?.orderDate ? Util.getLocalTime(order?.orderDate) : ""}</ContentDetail>
            </Content>
            <Content fontSize="20px">
              <p>{intl.get("order.status")}</p>
              <ContentDetail fontSize="20px">
                {order?.currentStatus ? intl.get("order.status." + order?.currentStatus): ""}
                {order?.currentStatus == "finished" && (<span>({intl.get("order.status." + order?.finished)})</span> )}
              </ContentDetail>
            </Content>
          </ul>
          {!appInfo.isWholesaleApp && (
            <>
              <Subject fontSize="28px" paddingBottom="22px">
                {intl.get("order.buyerInfo")}
              </Subject>
              <ul>
                <Content fontSize="20px">
                  <p>{intl.get("order.realName")}</p>
                  <ContentDetail fontSize="20px">{order?.userName}</ContentDetail>
                </Content>
                {/*<Content fontSize="20px">*/}
                {/*  <p>{intl.get("order.idCard")}</p>*/}
                {/*  <ContentDetail fontSize="20px">{intl.get("common.button.show")}</ContentDetail>*/}
                {/*</Content>*/}
              </ul>
            </>
           )}
          <Subject fontSize="28px">
            {intl.get("order.deliveryInfo")}
          </Subject>
          <ul>
            <Content fontSize="20px">
              <p>{intl.get("order.recipient")}</p>
              <ContentDetail fontSize="20px">{order?.shippingAddress.recipient}</ContentDetail>
            </Content>
            <Content fontSize="20px">
              <p>{intl.get("order.phone")}</p>
              <ContentDetail fontSize="20px">{order?.shippingAddress.phone}</ContentDetail>
            </Content>
            <Content fontSize="20px">
              <p>{intl.get("order.address")}</p>
              <ContentDetail fontSize="20px">
                {getOrderAddress()}
              </ContentDetail>
              {canCancel && (
                <DeliveryDetailBtn onClick={() => setVisibleAddressModal(true)}>
                  {intl.get("order.change.shippingAddress")}
                </DeliveryDetailBtn>
              )}
            </Content>
            <Content fontSize="20px">
              <p>{intl.get("order.userComment")}</p>
              <ContentDetail fontSize="20px">{order?.shippingAddress.userComments}</ContentDetail>
            </Content>
            <Content fontSize="20px">
              <p>{intl.get("order.deliveryNo")}</p>
              <ContentDetail fontSize="20px">{order?.orderDelivery.deliveryNo}</ContentDetail>
              {order?.orderDelivery.deliveryNo && (
                <DeliveryDetailBtn
                  onClick={() => {
                    onClickDeliveryBtn(orderId);
                  }}
                >
                  {intl.get("order.delivery.tracking")}
                </DeliveryDetailBtn>
              )}
            </Content>
            <Content fontSize="20px">
              <p>{intl.get("order.deliveredAt")}</p>
              <ContentDetail fontSize="20px">{order?.deliveredAt.toString().substring(0, 10)}</ContentDetail>
            </Content>
          </ul>
          <Subject fontSize="28px">
            {intl.get("order.list")}
          </Subject>
          {order?.orderSkus.map((sku, i) => (
            <SkusItem key={i}>
              {sku.skuImage && (
                <img src={sku.skuImage} alt="skuImage" />
              )}
              <SkusContent>
                <SkusSubject>
                  <span>{sku.productName}</span>
                  <span className="skusOption">{sku.customsOption}</span>
                </SkusSubject>
                <SkusPrices>
                  <MypagePrice amount={sku.salesPrice} fontSize="20px" color={error}/>
                  <SkusQuantity>x{sku.quantity}</SkusQuantity>
                </SkusPrices>
              </SkusContent>
            </SkusItem>
          ))}
          <Subject fontSize="28px">
            {intl.get("order.paymentAmount")}
          </Subject>
          <ul>
            <PriceContent>
              <p>{intl.get("order.orderAmount")}</p>
              <MypagePrice amount={order?.orderAmount} fontSize="20px" />
            </PriceContent>
            <PriceContent>
              <p>{intl.get("order.deliveryFee")}</p>
              <MypagePrice amount={order?.deliveryFee} fontSize="20px" />
            </PriceContent>
            <PriceContent>
              <p>{intl.get("order.discountAmount")}</p>
              <MypagePrice amount={order?.discountAmount} fontSize="20px" />
            </PriceContent>
            <PaymentAmount>
              <p>{intl.get("order.paymentAmountUser")}</p>
              <MypagePrice amount={order?.paymentAmount} fontSize="30px" color={error}/>
            </PaymentAmount>
          </ul>
        </ul>
        <ButtonWrapper>
          { canCancel && (
            <CancelOrderBtn onClick={onClickCancelOrder}>
              {intl.get("order.cancel")}
            </CancelOrderBtn>
          )}
          <ListOrderBtn>
            <Link to="/mypage">{intl.get("order.list")}</Link>
          </ListOrderBtn>
        </ButtonWrapper>

      </Container>
      {visibleAddressModal && (
        <ChangeShippingAddressModal
          order={order}
          onCancel={() => setVisibleAddressModal(false)}
          onOk={saveShippingAddress}
        />
      )}
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 72px 0 80px;
`;

const Content = styled.li`
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
  margin-top: 20px;
  :last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  p {
    font-size: ${(props) => (props.fontSize ? props.fontSize : "16px")};
    font-weight: 720;
    margin-left: 8px;
    margin-right: 20px;
    margin-bottom: 0;
    width: 140px;
  }

  input {
    width: 820px;
    height: 100%;
    box-sizing: border-box;
    font-size: 16px;
    border: 1px solid #e0e0e0;
  }

  .essentialSign {
    position: absolute;
    width: 6px;
    top: 14px;
    left: 2px;
  }
`;

const ContentDetail = styled.div`
  font-size:  ${(props) => (props.fontSize ? props.fontSize: "16px")};
  font-weight: 400;
  color: ${gray600};
  max-width: 700px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  -webkit-line-clamp: 1;
  box-sizing: border-box;
`;

const SkusItem = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 148px;
  width: 100%;
  margin-top: 20px;
  :first-child {
    margin-top: 24px;
  }
`;

const SkusContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 819px;
  height: 100%;
`;

const SkusSubject = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 700;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .skusOption {
    color: ${gray400};
    font-size: 14px;
    font-weight: 400;
  }
`;

const SkusPrices = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 700;
`;

const SkusQuantity = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${gray400};
`;

const PriceContent = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 20px;
  :last-child {
    border-bottom: none;
  }

  p {
    font-size: 16px;
    font-weight: 700;
    margin-left: 8px;
    margin-bottom: 0;
  }
  div {
    margin-right: 5px;
  }
`;

const PaymentAmount = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 26px;
  border-top: 2px solid black;
  padding-top: 20px;
  :last-child {
    border-bottom: none;
  }

  p {
    font-size: 18px;
    font-weight: 700;
    margin-left: 8px;
    color: ${gray600};
  }
  div {
    margin-right: 5px;
  }
`;

const DeliveryDetailBtn = styled.div`
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 32px;
  border: 1px solid #3352f6;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  color: ${mainColor2};
  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease-out;

  :hover {
    background-color: ${mainColor2};
    color: white;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin-top: 104px;
`;

const CancelOrderBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 276px;
  height: 72px;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${gray600};
  color: white;
  font-size: 20px;
  font-weight: 700;
`;

const ListOrderBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 276px;
  height: 72px;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${mainColor2};
  color: white;
  font-size: 20px;
  font-weight: 700;
  
  a {
    color: inherit;
  }
`;

export default OrderDetail;
