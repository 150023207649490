import axios from "axios";
import { adminConfig } from "../../config/admin.config";

const API_BASE_URL = adminConfig.apiServer();

export default class UserApi {
  static getCurrent = () => {
    const apiPath = `${API_BASE_URL}/users/current`;
    return axios.get(apiPath);
  };

  static findPassword = (loginId, email) => {
    const apiPath = `${API_BASE_URL}/users/find-password`;
    const params = {
      loginId: loginId,
      email: email,
    }
    return axios.put(apiPath, params);
  }

  static changePassword = (token, password) => {
    const apiPath = `${API_BASE_URL}/users/reset-password`;
    const params = {
      token: token,
      password: password,
    }
    return axios.put(apiPath, params);
  }
}
