import Cookies from "universal-cookie";

const cookies = new Cookies();

const getCookie = (name) => {
  return cookies.get(name);
};

const setCookie = (name, value, option) => {
  return cookies.set(name, value, { path: "/", ...option });
};

const deleteCookie = (name) => {
  return cookies.remove(name, { path: "/" });
};

export { setCookie, deleteCookie, getCookie };
