import React, { useState, useEffect, useCallback } from "react";
import intl from "react-intl-universal";
import styled from "styled-components";

import AddressData from "./AddressData";

const AddressSelect = (props) => {
  const provinces = AddressData.getProvinces();
  const cityMap = AddressData.getCityMap();
  const countryMap = AddressData.getCountryMap();

  const [editingAddress, setEditingAddress] = useState({
    cities: [],
    counties: [],
    province: "",
    city: "",
    country: "",
    provinceName: "",
    cityName: "",
    countryName: "",
    addressDetail: "",
  });

  useEffect(() => {
    if (props.addressCode) {
      console.log(">>>>setAddress:", props.addressDetail);
      setAddress(props.addressCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.addressCode]);

  const setAddress = (addressCode) => {
    let provinceCode = "";
    let cityCode = "";
    let provinceName = "";
    let cityName = "";
    let countryName = "";

    if (addressCode) {
      provinceCode = addressCode.substring(0, 2);
      cityCode = addressCode.substring(0, 4);
      const province = provinces.find((p) => p.key === provinceCode);
      provinceName = province ? province.value : "";
    } else {
      provinceCode = "11";
    }

    const cities = cityMap[provinceCode] ? cityMap[provinceCode] : [];
    const city = cities.find((c) => c.key === cityCode);
    if (city) {
      cityName = city.value;
    }

    const counties = countryMap[cityCode] ? countryMap[cityCode] : [];
    const country = counties.find((c) => c.key === addressCode);
    if (country) {
      countryName = country.value;
    }

    const editingAddressState = { ...editingAddress };
    const selectedAddress = {
      cities: cities,
      counties: counties,
      province: provinceCode,
      city: cityCode,
      country: addressCode,
      provinceName: provinceName,
      cityName: cityName,
      countryName: countryName,
      addressDetail: props.addressDetail,
    };
    Object.assign(editingAddressState, selectedAddress);
    setEditingAddress(editingAddressState);

    // props.selectCounty(
    //   editingAddressState.provinceName,
    //   editingAddressState.cityName,
    //   editingAddressState.countryName,
    //   addressCode,
    //   editingAddressState.addressDetail
    // );
  };

  const handleChangeProvince = (v) => {
    const provinceCode = v.key.substring(0, 2);
    const editingAddressState = { ...editingAddress };
    const selectedAddress = {
      cities: cityMap[provinceCode] ? cityMap[provinceCode] : [],
      provinceName: v.label,
      province: v.value,
      city: "",
      cityName: "",
      country: "",
      countryName: "",
    };

    Object.assign(editingAddressState, selectedAddress);
    setEditingAddress(editingAddressState);
  };

  const handleChangeCity = (value) => {
    if (value.key === "") {
      return;
    }
    const cityCode = value.key.substring(0, 4);

    const editingAddressState = { ...editingAddress };
    const selectedAddress = {
      counties: countryMap[cityCode] ? countryMap[cityCode] : [],
      city: value.key,
      cityName: value.label,
      country: "",
      countryName: "",
    };

    Object.assign(editingAddressState, selectedAddress);
    setEditingAddress(editingAddressState);
  };

  const handleChangeCounty = (value) => {
    if (value.key === "") {
      return;
    }
    const editingAddressState = { ...editingAddress };
    const selectedAddress = {
      country: value.key,
      countryName: value.label,
    };

    Object.assign(editingAddressState, selectedAddress);
    setEditingAddress(editingAddressState);
  };

  const handleChangeAddressDetail = (e) => {
    const editingAddressState = {
      ...editingAddress,
      addressDetail: e.target.value,
    };
    setEditingAddress(editingAddressState);
  };

  const onClickSubmitBtn = useCallback(() => {
    if (
      !editingAddress.cityName ||
      !editingAddress.countryName ||
      !editingAddress.provinceName ||
      !editingAddress.addressDetail
    ) {
      alert(intl.get("common.inputField", { name: intl.get("order.address") }));
      return;
    }
    props.onOk(
      editingAddress.provinceName,
      editingAddress.cityName,
      editingAddress.countryName,
      editingAddress.country,
      editingAddress.addressDetail
    );
  }, [editingAddress, props]);

  if (provinces.length === 0) {
    return <div></div>;
  }

  return (
    <Container>
      {!props.hideTitle && (<span>{intl.get("order.addressInput")}</span>)}
      <SelectWrapper>
        <select
          disabled={props.disableSelection}
          value={editingAddress.province}
          onChange={(e) => {
            handleChangeProvince({
              label: e.target.options[e.target.selectedIndex].text,
              value: e.target.value,
              key: e.target.value,
            });
          }}
        >
          <option value="" key="none">
            {intl.get("order.province")}
          </option>
          {provinces.map((province, index) => (
            <option value={province.key} key={province.key}>
              {province.value}
            </option>
          ))}
        </select>
        <select
          disabled={props.disableSelection}
          value={editingAddress.city}
          onChange={(e) => {
            handleChangeCity({
              label: e.target.options[e.target.selectedIndex].text,
              value: e.target.value,
              key: e.target.value,
            });
          }}
        >
          <option value="" key="none">
            {intl.get("order.city")}
          </option>
          {editingAddress.cities.map((city, index) => (
            <option value={city.key} key={city.key}>
              {city.value}
            </option>
          ))}
        </select>
        <select
          disabled={props.disableSelection}
          value={editingAddress.country}
          onChange={(e) => {
            handleChangeCounty({
              label: e.target.options[e.target.selectedIndex].text,
              value: e.target.value,
              key: e.target.value,
            });
          }}
        >
          <option value="" key="none">
            {intl.get("order.district")}
          </option>
          {editingAddress.counties.map((country, index) => (
            <option value={country.key} key={country.key}>
              {country.value}
            </option>
          ))}
        </select>
      </SelectWrapper>
      <AddressDetailInput
        value={editingAddress.addressDetail || ""}
        placeholder={intl.get("common.inputField", {
          name: intl.get("order.address-detail"),
        })}
        onChange={handleChangeAddressDetail}
      />
      {props.disableSelection && (
        <div style={{padding: 10}}>
          {intl.get("address.otherCounrty")}
        </div>
      )}
      <BtnWarpper>
        <Button color="#9E9E9E" onClick={props.onCancel}>
          {intl.get("common.button.cancel")}
        </Button>
        <Button color="#3352F6" onClick={onClickSubmitBtn}>
          {intl.get("common.button.ok")}
        </Button>
      </BtnWarpper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  padding: 20px 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  span {
    font-size: 24px;
    font-weight: 700;
  }
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 756px;
  margin: 0 auto;
  select {
    width: 232px;
    height: 44px;
    font-size: 16px;
    border: 1px solid #9e9e9e;
    border-radius: 2px;
    padding: 10px 12px;
  }
`;

const AddressDetailInput = styled.input`
  width: 756px;
  height: 44px;
  box-sizing: border-box;
  border: 1px solid #9e9e9e;
  border-radius: 2px;
  font-size: 16px;
  padding: 10px 16px;
`;

const BtnWarpper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 756px;
  height: 52px;
`;

const Button = styled.button`
  width: 368px;
  color: white;
  background-color: ${(props) => props.color};
  font-size: 16px;
  font-weight: 700;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

export default AddressSelect;
