import React, { useContext } from "react";
import styled from "styled-components";
import CancelBtn from "../../assets/cancelBtn.png";

import AppInfoContext from "../../contexts/AppInfoContext";
import intl from "react-intl-universal";
import {gray500} from "../../styles/fontColors";

function SkuTotalPrice({ originPriceTotal, secondaryPriceTotal, paymentPriceTotal }) {
  const { appInfo, appDesign } = useContext(AppInfoContext);
  const { priceDisplaySetting } = appDesign;

  let showSubCurrency = false;
  let mainPriceSymbol = "¥";
  let subPriceSymbol = "¥";
  let salePriceMainAmount = 0;
  let salePriceSubAmount = 0;

  if (priceDisplaySetting) {
    showSubCurrency = priceDisplaySetting.subCurrency !== "none" && appInfo.secondaryCurrency !== appInfo.productCurrency;

    /////////////////////////////////////////////////////
    // main price 설정
    salePriceMainAmount = secondaryPriceTotal;
    if (priceDisplaySetting?.mainCurrency === "product") {
      mainPriceSymbol = appInfo.productSymbol;
      salePriceMainAmount = originPriceTotal;
    } else if (priceDisplaySetting?.mainCurrency === "payment") {
      mainPriceSymbol = appInfo.paymentSymbol;
      salePriceMainAmount = paymentPriceTotal;
    }
    // main price 설정
    /////////////////////////////////////////////////////

    /////////////////////////////////////////////////////
    // sub price 설정
    subPriceSymbol = "¥";
    salePriceSubAmount = secondaryPriceTotal;
    if (priceDisplaySetting?.subCurrency === "product") {
      subPriceSymbol = appInfo.productSymbol;
      salePriceSubAmount = originPriceTotal;
    } else if (priceDisplaySetting?.subCurrency === "payment") {
      subPriceSymbol = appInfo.paymentSymbol;
      salePriceSubAmount = paymentPriceTotal;
    }
  }

  return (
    <TotalPrice>
      <TotalPriceText>{intl.get("order.orderAmount")}</TotalPriceText>
      <TotalOriginPrice>{mainPriceSymbol}{salePriceMainAmount.toLocaleString("kr-KR")}</TotalOriginPrice>
      {showSubCurrency && (
        <TotalSecondaryPrice>
          {subPriceSymbol}
          {Number(salePriceSubAmount).toLocaleString("kr-KR")}
        </TotalSecondaryPrice>
      )}
    </TotalPrice>
  );
}

const TotalPrice = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin: 20px 0;
  padding: 0 16px;
  box-sizing: border-box;
  font-size: 16px;
`;
const TotalPriceText = styled.div`
  color: ${gray500};
  line-height: 36px;
`;
const TotalOriginPrice = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin: 0 8px 0 24px;
  line-height: 36px;
`;

const TotalSecondaryPrice = styled.div`
  margin-top: 5px;
  border-top: none !important;
`;

export default SkuTotalPrice;
