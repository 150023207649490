import React, { useEffect } from "react";
import { AuthService } from "../../auth/auth.service";
import { useHistory } from "react-router-dom";

function LoginSuccess(props) {
  const history = useHistory();
  useEffect(() => {
    AuthService.silentRefresh().then(() => {
      history.push("/");
    });
  }, []);

  return (<div></div>)
}

export default LoginSuccess;