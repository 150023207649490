import axios from "axios";
import { adminConfig } from "../../config/admin.config";

const API_BASE_URL = adminConfig.apiServer();

export default class CategoryApi {
  static getAllcategories = () => {
    const apiPath = `${API_BASE_URL}/categories`;

    return axios.get(apiPath);
  };

  static getCategoryItems = (param) => {
    const apiPath = `${API_BASE_URL}/categoryId=${param}`;

    return axios.get(apiPath);
  };
}
