import React, {useContext} from "react";
import styled from "styled-components";

import { gray600 } from "../../styles/fontColors";

import setPaymentCurrency from "../../utils/setCurrency";
import AppInfoContext from "../../contexts/AppInfoContext";

function MypagePrice({amount, fontSize = "20px", color = gray600}) {
  const { appInfo, appDesign } = useContext(AppInfoContext);
  const { priceDisplaySetting } = appDesign;

  let showSubCurrency = false;
  let mainPriceSymbol = "¥";
  let subPriceSymbol = "¥";
  let salePriceMainAmount = 0;
  let salePriceSubAmount = 0;

  if (amount) {
    showSubCurrency = priceDisplaySetting.subCurrency !== "none" && appInfo.secondaryCurrency !== appInfo.productCurrency;

    /////////////////////////////////////////////////////
    // main price 설정
    salePriceMainAmount = amount.secondaryPrice;
    if (priceDisplaySetting?.mainCurrency === "product") {
      mainPriceSymbol = appInfo.productSymbol;
      salePriceMainAmount = amount.originPrice;
    } else if (priceDisplaySetting?.mainCurrency === "payment") {
      mainPriceSymbol = appInfo.paymentSymbol;
      salePriceMainAmount = amount.paymentPrice;
    }
    // main price 설정
    /////////////////////////////////////////////////////

    /////////////////////////////////////////////////////
    // sub price 설정
    subPriceSymbol = "¥";
    salePriceSubAmount = amount.secondaryPrice;
    if (priceDisplaySetting?.subCurrency === "product") {
      subPriceSymbol = appInfo.productSymbol;
      salePriceSubAmount = amount.originPrice;
    } else if (priceDisplaySetting?.subCurrency === "payment") {
      subPriceSymbol = appInfo.paymentSymbol;
      salePriceSubAmount = amount.paymentPrice;
    }
  }

  return (
    <PriceWrapper fontSize={fontSize} color={color}>
      <div>
        {mainPriceSymbol + Number(salePriceMainAmount).toLocaleString("kr-KR")}
      </div>
      { (showSubCurrency && salePriceSubAmount !== 0)&& (
        <ProductCurrency fontSize={fontSize}>
          {subPriceSymbol + Number(salePriceSubAmount).toLocaleString("kr-KR")}
        </ProductCurrency>
      )}
    </PriceWrapper>
  );
}

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-size: ${(props) => props.fontSize};
  color: ${(props) => props.color};
  font-weight: 700;
`;

const ProductCurrency = styled.div`
  font-size: 16px;
  font-weight: 400;
  margin-left: 6px;
  line-height: ${(props) => (props.fontSize === "30px" ? "36px" : "28px")};
  color: ${gray600};
`;

export default MypagePrice;
