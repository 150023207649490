import axios from "axios";

import { adminConfig } from "../../config/admin.config";

const API_BASE_URL = adminConfig.apiServer();
export default class OrderApi {
  static getOrder = (orderId) => {
    const apiPath = `${API_BASE_URL}/orders/${orderId}`;
    return axios.get(apiPath);
  };

  static getOrders = (params) => {
    let apiPath = `${API_BASE_URL}/orders?`;

    const paramKeyValues = [];
    Object.keys(params).forEach((key) => {
      if (params[key]) {
        paramKeyValues.push(key + "=" + params[key]);
      }
    });
    apiPath += paramKeyValues.join("&");
    // if (!params.projection) {
    //   apiPath += "&projection=webAdmin";
    // }
    return axios.get(apiPath);
  };

  static download = (options) => {
    let apiPath = `${API_BASE_URL}/orders/download&`;

    apiPath += Object.keys(options)
      .map((key) => {
        return key + "=" + options[key];
      })
      .join("&");

    return axios.get(apiPath);
  };

  static prepareOrder = (params) => {
    const apiPath = `${API_BASE_URL}/orders/prepare`;
    return axios.post(apiPath, params);
  };

  static createOrder = (order) => {
    const apiPath = `${API_BASE_URL}/orders`;
    console.log("orderREQ", order);
    return axios.post(apiPath, order);
  };

  static saveOrder = (order) => {
    const apiPath = `${API_BASE_URL}/orders/${order.id}`;
    return axios.put(apiPath, order);
  };

  static saveShippingAddress = (orderId, shippingAddress) => {
    const apiPath = `${API_BASE_URL}/orders/${orderId}/shipping-address`;
    return axios.put(apiPath, shippingAddress);
  };

  static cancelOrder = (orderId) => {
    const apiPath = `${API_BASE_URL}/orders/${orderId}/cancel`;
    return axios.put(apiPath);
  };

  static getOrderPayment = (orderId) => {
    const apiPath = `${API_BASE_URL}/orders/${orderId}/payment`;
    return axios.get(apiPath);
  };

  static getOrderPaymentHistories = (orderId) => {
    const apiPath = `${API_BASE_URL}/orders/${orderId}/payment-histories`;
    return axios.get(apiPath);
  };

  static uploadOrderDeliveries = (orderDeliveries) => {
    const apiPath = `${API_BASE_URL}/deliveries/upload`;
    return axios.post(apiPath, orderDeliveries);
  };

  static getDeliveryStatus = (orderId) => {
    const apiPath = `${API_BASE_URL}/orders/${orderId}/delivery-status`;
    return axios.get(apiPath);
  };
}
