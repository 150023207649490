import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import WechatPay from "../../assets/wechatpay.png";
import QrScan from "../../assets/qrscan.png";
import { adminConfig } from "../../config/admin.config";

import setPaymentCurrency from "../../utils/setCurrency";
import { error, gray500, gray600, mainColor2 } from "../../styles/fontColors";

const WS_BASE_URL = adminConfig.wsServer();
var QRCode = require("qrcode.react");

function OrderPay({ match }) {
  const history = useHistory();
  const orderNo = match.params.orderNo;

  const location = useLocation();
  const stateParam = location.state;
  let webSocket = null;
  let checkOrderStatusInterval;
  useEffect(() => {
    // console.log("stateParam :>> ", stateParam);
    openWebSocket();
    return () => {
      stopWebSocket();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const openWebSocket = () => {
    if ("WebSocket" in window) {
      webSocket = new WebSocket(
        `${WS_BASE_URL}/orders/${stateParam.orderId}/checkOrderStatus`
      );
    } else {
      alert("该浏览器不支持WebSocket！");
    }
    webSocket.onopen = (event) => {
      // console.log("create conn :>> ", event);
      checkOrderStatusInterval = setInterval(() => {
        webSocket.send("checkOrderStatus");
      }, 2000);
    };
    webSocket.onclose = (event) => {
      console.log("close conn :>> ", event);
      clearInterval(checkOrderStatusInterval);
    };
    webSocket.onmessage = (event) => {
      console.log("received message :>> ", event.data);
      const obj = JSON.parse(event.data);
      if (obj.currentStatus === "paid") {
        //pay success
        stopWebSocket();
        alert("지불 성공");
        history.push({
          pathname: "/success",
          state: {
            orderNo,
            orderId: stateParam.orderId,
            orderDate: stateParam.orderDate,
          },
        });
      }
      if (obj.finishedStatus === "failed") {
        //pay failed
        stopWebSocket();
        alert("지불 실패");
        history.push("/failed");
      }
    };
  };
  const stopWebSocket = () => {
    checkOrderStatusInterval && clearInterval(checkOrderStatusInterval);
    webSocket && webSocket.close();
  };
  return (
    <Container>
      <div className="title-box">
        订单提交成功，请尽快付款！订单号：{orderNo}
      </div>

      <div className="content-box">
        <div className="pay-info">
          <span style={{ fontSize: "20px" }}>微信支付</span>
          <span style={{ fontSize: "16px", marginLeft: "64px" }}>
            {/* 二维码已过期，刷新页面重新获取二维码。 */}
          </span>
          <div style={{ flex: 1, textAlign: "right" }}>
            <span style={{ fontSize: "16px" }}>
              <span>应付金额</span>
              <span className="price-l">
                {setPaymentCurrency(stateParam.paymentCurrency)}
                {stateParam.paymentAmount?.paymentPrice}
              </span>
              <span className="price-r">
                {setPaymentCurrency(stateParam.productCurrency)}
                {stateParam.paymentAmount?.productPrice}
              </span>
            </span>
          </div>
        </div>
        <div className="img-box">
          <div>
            {<QRCode value={stateParam.wxPayLink} size={280} />}
            <div className="scan-desc">
              <img src={QrScan} alt="" />
              <div
                style={{ width: "112px", marginLeft: "12px", fontSize: "14px" }}
              >
                请使用微信扫一扫 扫描二维码支付
              </div>
            </div>
          </div>
          <img src={WechatPay} alt="" style={{ marginLeft: "56px" }} />
        </div>
      </div>
      {/* <button onClick={openWebSocket}>ws open</button> */}
      {/* <button onClick={stopWebSocket}>ws close</button> */}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .title-box {
    padding: 24px 0;
    font-size: 16px;
    color: ${gray600};
  }
  .content-box {
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
    width: 988px;
    height: 631px;
    padding: 30px 24px;
    border-radius: 1px;
    border: solid 2px #eee;
    .pay-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: ${gray500};
      .price-l {
        margin-left: 20px;
        font-weight: 700;
        font-size: 24px;
        color: ${error};
      }
      .price-r {
        margin-left: 6px;
        font-size: 16px;
        color: ${gray500};
        line-height: 22px;
        margin-left: 6px;
      }
    }
    .img-box {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 24px;
      .scan-desc {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 280px;
        height: 60px;
        margin: 16px 0 0;
        background-color: ${mainColor2};
        color: #fff;
      }
    }
  }
`;

export default OrderPay;
