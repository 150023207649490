const BetterAdminConfig = {
  siteName: "YOUMA",
  version: "1.0.0",
  logo: "/better-admin.png",
  homePage: "/",
  authentication: {
    used: true,
    loginUrl: "/login",
    oauthLoginResultUrl: "/oauth-login-result",
  },
  adminWebServer: () => {
    const serviceDomain = getServiceDomainName();
    const subDomain = serviceDomain === "eryuan.co" ? "sales" : "admin";
    if (process.env.REACT_APP_ENV === "production") {
      return `https://${subDomain}.${serviceDomain}`;
    } else if (process.env.REACT_APP_ENV === "staging") {
      return `https://staging-${subDomain}.${serviceDomain}`;
    } else {
      return "http://localhost:3006";
    }
  },
  apiServer: () => {
    const appName = process.env.REACT_APP_NAME;
    const serviceDomain = appName === "youma" ? "youma.kr" : "eryuan.co";
    const apiSubDomain = serviceDomain === "eryuan.co" ? "youma-api" : "api";
    if (process.env.REACT_APP_ENV === "production") {
      return `https://${apiSubDomain}.${serviceDomain}`;
    } else if (process.env.REACT_APP_ENV === "staging") {
      return `https://staging-${apiSubDomain}.${serviceDomain}`;
    } else {
      return "http://localhost:7201";
    }
  },
  wsServer: () => {
    const serviceDomain = getServiceDomainName();
    const apiSubDomain = serviceDomain === "eryuan.co" ? "youma-api" : "api";
    if (process.env.REACT_APP_ENV === "production") {
      return `wss://${apiSubDomain}.${serviceDomain}`;
    } else if (process.env.REACT_APP_ENV === "staging") {
      return `wss://staging-${apiSubDomain}.${serviceDomain}`;
    } else {
      return "ws://localhost:7201";
    }
  },
  errorMessage: {
    serverInternalError: "오류가 발생 했습니다. 잠시 후 다시 시도해 주세요.",
    badRequestError: "잘못된 요청입니다.",
    pageNotFoundError: "페이지를 찾을 수 없습니다.",
    networkError: "네트워크 연결이 원활하지 않습니다. 다시 한번 시도해 주세요.",
    badAccessPathError: "잘못된 경로 입니다. 메뉴를 통해 접근해 주세요.",
  },
  serverErrorHandlingExcludeUrl: {
    badRequest: ["/auth/token/refresh", "/auth/login-with-id", "/sms/send", "/sms/verify"],
  },
};

const getServiceDomainName = () => {
  const hostName = window.location.hostname;
  if (hostName === "localhost" || hostName === "127.0.0.1") {
    return hostName;
  }
  const hostTokens = hostName.split(".");
  if (hostTokens.length >= 2) {
    return hostTokens[hostTokens.length - 2] + "." + hostTokens[hostTokens.length - 1];
  } else {
    return "youma.kr";
  }
}

export const adminConfig = BetterAdminConfig;
