import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import intl from "react-intl-universal";
import styled from "styled-components";

import { ReactComponent as ArrowRight } from "../../assets/arrow-right.svg";
import { gray500 } from "../../styles/fontColors";

import Subject from "../list/Subject";
import MypagePrice from "../priceList/MypagePrice";
import Util from "../../utils/Util";

function OrderListItem({order}) {
  const history = useHistory();

  const onClickOrderNum = useCallback(() => {
    history.push(`/order/${order.id}`);
  }, [order.id, history]);

  return (
    <>
      <ul>
        <Subject onClick={onClickOrderNum} cursor="pointer" fontSize="20px" height="44px" orderId={order.id}>
          <SubjectContents>
            {intl.get("order.orderNo")}&nbsp; {order.orderNo}
            <ArrowRight />
          </SubjectContents>
        </Subject>
      </ul>
      <Container>
        <img src={order.featuredImage} alt="itemImg"></img>
        <Content>
          <Detail>
            <DetailSubject>
              {intl.get("order.orderDate")}
            </DetailSubject>
            {order.orderDate ? Util.getLocalTime(order.orderDate) : ""}
          </Detail>
          <Detail>
            <DetailSubject>{intl.get("order.productName")} </DetailSubject>{" "}
            <DetailContent>{order.orderTitle}</DetailContent>
          </Detail>
          <Detail>
            <DetailSubject>{intl.get("order.status")} </DetailSubject>{" "}
            <DetailContent>{order.currentStatus}</DetailContent>
          </Detail>
          <Detail>
            {" "}
            <DetailSubject>
              {intl.get("order.paymentAmountUser")}{" "}
            </DetailSubject>
            <MypagePrice amount={order.paymentAmount}/>
          </Detail>
        </Content>
      </Container>
    </>
  );
}

const Container = styled.ul`
  display: flex;
  height: 148px;
  width: 100%;
  margin: 20px 0 36px;
  :first-child {
    margin-top: 24px;
  }
`;

const SubjectContents = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  box-sizing: border-box;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 819px;
  height: 100%;
  margin-left: 20px;
`;

const Detail = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
`;

const DetailSubject = styled.div`
  width: 96px;
  font-size: 16px;
  font-weight: 400;
  color: ${gray500};
  margin-right: 72px;
`;

const DetailContent = styled.div`
  max-width: 600px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export default OrderListItem;
