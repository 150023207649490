import axios from "axios";
import { adminConfig } from "../config/admin.config";
import { setCookie } from "../cookie/setCookie";
import { MemberContext } from "./member.context";
import Util from "../utils/Util";

const API_URL = adminConfig.apiServer();

class AuthService {
  async login(smsCode, bizId) {
    const params = {
      code: smsCode,
      bizId: bizId,
      template: "login",
    };
    const authResponse = await axios.post(
      API_URL + "/auth/login-phone",
      params
    );
    const { accessToken } = authResponse.data;
    axios.defaults.headers["Authorization"] = `Bearer ${accessToken}`;
    const memberResponse = await axios.get(API_URL + "/users/current");
    MemberContext.memberInformation = memberResponse.data;
    setCookie("userId", memberResponse.data.id);
  }

  async loginWithId(loginId, password) {
    const params = {
      loginId: loginId,
      password: password,
    };
    const authResponse = await axios.post(
      API_URL + "/auth/login-with-id-web",
      params
    );
    const { accessToken } = authResponse.data;
    axios.defaults.headers["Authorization"] = `Bearer ${accessToken}`;
    const memberResponse = await axios.get(API_URL + "/users/current");
    MemberContext.memberInformation = memberResponse.data;
    setCookie("userId", memberResponse.data.id);
  }

  async register(user) {
    const response = await axios
      .post(API_URL + "/auth/register-id", user)
      .catch((err) => {
        throw err;
      });
    const authResponse = response.data;
    axios.defaults.headers[
      "Authorization"
    ] = `Bearer ${authResponse.accessToken}`;
    const memberResponse = await axios.get(API_URL + "/users/current");
    MemberContext.memberInformation = memberResponse.data;
  }

  logout() {
    Util.deleteAllCookies();
    return axios.post(API_URL + "/auth/logout").then(() => {
      delete axios.defaults.headers["Authorization"];
    });
  }

  checkAuth() {
    // TODO 오류를 대비해 Retry 추가해야
    return axios.get(API_URL + "/auth/check");
  }

  async silentRefresh() {
    delete axios.defaults.headers["Authorization"];
    const authResponse = await axios.post(API_URL + "/auth/refresh");
    const { accessToken } = authResponse.data;
    axios.defaults.headers["Authorization"] = `Bearer ${accessToken}`;

    const memberResponse = await axios.get(API_URL + "/users/current");
    MemberContext.memberInformation = memberResponse.data;
  }
}

const instance = new AuthService();
export { instance as AuthService };
