import ReactModal from "react-modal";
import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";

import CancelBtnImg from "../../assets/cancelBtn.png";
import { gray500 } from "../../styles/fontColors";

import OrderDeliveryPopup from "../deliveryPopup/OrderDeliveryPopup";
import AppInfoContext from "../../contexts/AppInfoContext";

const CommonModal = ({
  isOpen,
  modalContent,
  handleModal,
  color = gray500,
  order = "",
  selectCountry = "",
  width = "484px",
  height = modalContent === "signup" ? "550px" : "419px",
}) => {
  const [showModal, setShowModal] = useState(false);
  const { appInfo } = useContext(AppInfoContext);

  useEffect(() => {
    setShowModal(isOpen);
  }, [isOpen]);

  const handleCloseModal = () => {
    handleModal("");
    setShowModal(false);
  };

  return (
    <ReactModal
      isOpen={showModal}
      onRequestClose={handleCloseModal}
      shouldCloseOnOverlayClick={true}
      style={{
        overlay: {position: "fixed", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: color},
        content: {
          position: "absolute",
          width: width,
          height: height,
          margin: "auto",
          border: "1px solid #ccc",
          background: "#fff",
          overflow: "none",
          WebkitOverflowScrolling: "touch",
          borderRadius: "8px",
          outline: "none",
          padding: "29px",
          boxSizing: "border-box",
        },
      }}
    >
      <CancelBtn
        src={CancelBtnImg}
        alt="cancelBtn"
        onClick={handleCloseModal}
      />
      {/* <div>모달 입니다.</div> */}
      {modalContent === "delivery" && (
        <OrderDeliveryPopup
          handleModal={handleModal}
          id={order}
          application={appInfo}
        />
      )}
    </ReactModal>
  );
};

const CancelBtn = styled.img`
  position: absolute;
  right: 29px;
  top: 29px;
  height: 14px;
  width: 14px;
  cursor: pointer;
`;

export default CommonModal;
