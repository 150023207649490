import axios from "axios";
import { adminConfig } from "../../config/admin.config";

const API_BASE_URL = adminConfig.apiServer();

export default class ApplicationApi {
  static getCurrent = (domain) => {
    const apiPath = `${API_BASE_URL}/applications/current?domain=${domain}`;
    return axios.get(apiPath);
  }
}