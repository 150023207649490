import ReactModal from "react-modal";
import CancelBtnImg from "../../assets/cancelBtn.png";
import AddressSelect from "./AddressSelect";
import OrderDeliveryPopup from "../deliveryPopup/OrderDeliveryPopup";
import styled from "styled-components";
import React from "react";
import {gray500} from "../../styles/fontColors";

const AddressModal = (props) => {
  return (
    <ReactModal
      isOpen={true}
      onRequestClose={props.onCancel}
      shouldCloseOnOverlayClick={true}
      style={{
        overlay: {position: "fixed", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: gray500},
        content: {
          position: "absolute",
          width: "820px",
          height: "350px",
          margin: "auto",
          border: "1px solid #ccc",
          background: "#fff",
          overflow: "none",
          WebkitOverflowScrolling: "touch",
          borderRadius: "8px",
          outline: "none",
          padding: "29px",
          boxSizing: "border-box",
        },
      }}
    >
      <CancelBtn
        src={CancelBtnImg}
        alt="cancelBtn"
        onClick={props.onCancel}
      />
      <AddressSelect
        addressCode={props.addressCode}
        addressDetail={props.addressDetail}
        disableSelection={props.disableSelection}
        onCancel={props.onCancel}
        onOk={props.onOk}
      />
    </ReactModal>
  );
};

const CancelBtn = styled.img`
  position: absolute;
  right: 29px;
  top: 29px;
  height: 14px;
  width: 14px;
  cursor: pointer;
`;

export default AddressModal;