const messageZhCN = {
  "header.mypage": "我的",
  "header.login": "登录",
  "header.logout": "退出",
  "header.signup": "注册",
  "header.findPassword": "找回密码",
  "header.resetPassword": "设置密码",

  "common.button.ok": "确认",
  "common.button.cancel": "取消",
  "common.button.buy": "购买",
  "common.button.pay": "付款",
  "common.button.verify": "发验证码",
  "common.button.go-home": "跳转首页",
  "common.veryfication-code": "验证码",
  "common.button.show": "查看",

  "common.message.sent-code": "已发送验证码",
  "common.message.get-code": "发送短信验证码",
  "common.message.contactAdmin": "系统问题。请联系系统管理员",
  "common.message.request-login": "请登录后使用。",
  "common.message.moveToLogin": "跳转登录页面？",
  "common.message.complete-order": "订单已完成",
  "common.message.timer": "剩余时间", // ex) 인증 만료까지 10초가 남아있습니다.
  "common.message.timer-over": "验证码已过去",
  "common.message.selectAtLeastOne": "请选择至少一个{name}",
  "common.message.login": "请登录使用",
  "common.inputField": "请输入{name}",
  "common.message.saved": "已保存",
  "common.message.notFound": "信息不存在",

  "common.label.count": "条",
  "common.label.all": "全部订单",

  "common.error.1": "此账号不存在",
  "common.error.2": "超过限制次数。请稍后再试",
  "common.error.3": "验证码不存在",
  "common.error.4": "验证码不一致",
  "common.error.5": "验证模版不一致",
  "common.error.6": "验证码过去了",
  "common.error.7": "已注册电话号码",
  "common.error.8": "Code不存在",
  "common.error.10": "此账号已存在",
  "common.error.11": "注册密码要八个字符",
  "common.error.20": "确认账号或密码",
  "common.error.21": "此账号不存在",
  "common.error.22": "验证码已过去",
  "common.error.23": "账号和电话号码不一致",
  "common.error.999": "系统问题。请联系系统管理员",

  "user.info": "用户信息",
  "user.name": "姓名",
  "user.phone": "电话号码",
  "user.email": "电子邮箱",
  "user.loginId": "登录账号",
  "user.password": "密码",
  "user.confirmingPassword": "确认密码",
  "user.message.diffPassword": "两次密码输入不一致",
  "user.message.registered": "注册成功",
  "user.message.shortLoginId": "注册账号需要至少六位",
  "user.message.shortPassword": "注册密码要八个字符",
  "user.message.password.changed": "改密码成功",
  "user.message.agree1": "请同意会员条款",
  "user.message.agree2": "请同意隐私权条款",
  "user.message.sentPasswordMail": "密码找回邮件发送成功， 请您注意接收邮件",

  "terms.termsType.user": "会员条款",
  "terms.termsType.privacy": "隐私权条款",
  "terms.selectAll": "全选",

  "category": "分类",
  "category.first": "一级分类",
  "category.second": "二级分类",

  "products.tag": "标签", // 상품의 해시태그
  "products.wholesaleMinProducts": "起订量",
  "products.salesCondition": "订购条件",
  "products.message.minNums": "起订量{count}",
  "products.count": " ",

  "products.options": "产品选项",
  "order.status.wait-review": "평가 대기 중",
  "order.list": "订单目录",
  "order.orderInfo": "订单信息",
  "order.realName": "实名", // 실명 혹은 본명
  "order.addressInput": "输入地址",
  "order.userComment": "备注", // 주소 입력하고 나서 추가로 기입하는 공간
  "order.means-of-payment": "支付方式",
  "order.delivery-franchise": "가맹점 배송",
  "order.originCountry": "原产地",
  "order.province": "省",
  "order.city": "市",
  "order.district": "区",
  "order.address-detail": "详细地址",
  "order.productName": "产品名称",
  "order.order": "订单",
  "order.list.paymentInfo": "实收金额",
  "order.buyerInfo": "购买人信息",
  "order.list.recipientInfo": "收货人信息",
  "order.detail": "订单详情",
  "order.orderNo": "订单编号",
  "order.userName": "买家姓名",
  "order.phone": "电话号码",
  "order.status": "订单状态",
  "order.orderAmount": "商品价格",
  "order.paymentAmount": "付款金额",
  "order.paymentAmountPay": "支付金额调用",
  "order.paymentAmountUser": "实付款",
  "order.orderDate": "订单日期",
  "order.finishedAt": "交易成功日期",
  "order.idCardNo": "身份证",
  "order.recipient": "收货人",
  "order.address": "地址",
  "order.productAmount": "零售价",
  "order.discountAmount": "优惠",
  "order.deliveryFee": "运费",
  "order.deliveredAt": "发货日期",
  "order.refund": "是否退款",
  "order.refundAt": "退款日期",
  "order.orderDelivery": "配送",
  "order.offerListPrice": "产品价格",
  "order.quantity": "数量",
  "order.sku.shipQuantity": "数量",
  "order.wechatpay": "微信支付",
  "order.account_transfer": "转账",

  "order.status.": " ",
  "order.status.processing_clearance_9610": "通关中(9610)",
  "order.status.created": "生成订单",
  "order.status.prepared_wx_prepay": "确认支付中",
  "order.status.pay_prepared": "等待支付",
  "order.status.paid": "支付完成",
  "order.status.wait_pay": "待支付",
  "order.status.wx_customs_success": "传输支付单成功",
  "order.status.send_customs_success": "通过海关审核",
  "order.status.pay_declared": "海关审核中(支付申报完成)",
  "order.status.pay_declared1": "支付申报完成",
  "order.status.order_declaring": "海关审核中(订单申报中)",
  "order.status.order_declared": "海关审核中(订单申报完成)",
  "order.status.order_declared1": "订单申报完成",
  "order.status.declared": "已通关",
  "order.status.declaring": "通关中",
  "order.status.preparing_delivery": "准备发货",
  "order.status.prepare_delivery": "准备发货",
  "order.status.delivering": "配送中",
  "order.status.delivered": "配送完成",
  "order.status.finished": "已完成",
  "order.status.failed": "失败",
  "order.status.refund": "退款",
  "order.status.cancel": "取消",
  "order.status.user_cancel": "客户取消",
  "order.status.system_cancel": "自动取消",
  "order.failed.payment_prepay_failed": "准备支付失败",
  "order.failed.payment_failed": "支付失败",
  "order.failed.call_wx_customdeclareorder_failed": "传输支付单失败",
  "order.failed.call_trade_push_failed": "传输清单失败",
  "order.failed.trade_push_result_failed": "没通过海关审核",
  "order.status.success": "成功",
  "order.status.wait_agent": "PC等待发送",
  "order.status.agent_fetched": "PC已发送",
  "order.status.sent": "给保税区已发送",
  "order.reason.prepare_pay": "支付准备失败",
  "order.reason.payment": "支付失败",
  "order.reason.pay_declared": "海关申报（支付单）失败",
  "order.reason.order_declared": "海关申报（订单）",
  "order.reason.clearance": "海关申报失败",
  "order.reason.etc": "其它",
  "order.reason.none": " ",
  "order.declareInfo": "通关信息",
  "order.additional": "扩展信息",

  "order.deliveryInfo": "物流信息",
  "order.delivery.logisticsCompany": "物流企业名称",
  "order.delivery.tracking": "物流跟踪",
  "order.delivery.noTracking": "没有配送信息",
  "order.deliveryNo": "快递单号",
  "order.delivery.status": "배송상태",
  "order.delivery.input": "需要都填写一下项目信息。",
  "order.created": "订单已完成",
  "order.cancel": "订单取消",
  "order.cancel.confirm": "您确定取消订单吗？",
  "order.cancel.saved": "已取消",
  "order.message.krw1": "在网商店购买只能支付韩币 （KRW）",
  "order.message.krw2": "人民币支付请使用微信小程序",
  "order.payment.error": "支付有一场问题。请联系系统管理员",
  "order.change.shippingAddress": "修改地址",

  "brand.name": "品牌名称",

  "address.otherCounrty": "海外地址的话请在详细地址填写完整的地址"
};

export default messageZhCN;
