import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import intl from "react-intl-universal";

import WalletImg from "../../assets/icon-wallet.png";
import BoxImg from "../../assets/icon-box.png";
import DeliveryImg from "../../assets/icon-delivery.png";
import SpeechImg from "../../assets/icon-speech.png";
import SuccessImg from "../../assets/icon-success.png";
import {
  gray100,
  gray300,
  gray500,
  gray600,
  mainColor2,
} from "../../styles/fontColors";

import Content from "../../components/list/Content";
import Subject from "../../components/list/Subject";
import OrderListItem from "../../components/orderListItem/OrderListItem";
import { getCookie } from "../../cookie/setCookie";
import OrderApi from "../../components/apis/OrderApi";
import UserApi from "../../components/apis/UserApi";
import { MemberContext } from "../../auth/member.context";
import NoDataImg from "../../assets/no_data.png";

function Mypage() {
  const userId = getCookie("userId");
  const userContext = MemberContext.memberInformation;
  const history = useHistory();
  const [user, setUser] = useState({});
  const [orderList, setOrderList] = useState([]);
  const [orderClicked, setOrderClicked] = useState("all");
  const [orderDashboardItems, setOrderDashboardItems] = useState([
    { key: "all", name: intl.get("common.label.all"), count: 0, image: WalletImg },
    { key: "wait_pay", name: intl.get("order.status.wait_pay"), count: 0, image: WalletImg },
    { key: "preparing_delivery", name: intl.get("order.status.preparing_delivery"), count: 0, image: BoxImg },
    { key: "delivering", name: intl.get("order.status.delivering"), count: 0, image: DeliveryImg },
    { key: "delivered", name: intl.get("order.status.delivered"), count: 0, image: SpeechImg },
    { key: "finished", name: intl.get("order.status.finished"), count: 0, image: SuccessImg },
  ]);

  const onClickStatus = useCallback((v) => {
    // if (v === orderClicked) {
    //   setOrderClicked(null);
    // } else {
    //   setOrderClicked(v);
    // }
    setOrderClicked(v);
  },[orderClicked]);

  useEffect(() => {
    if (!userContext.id && !userId) {
      alert(intl.get("common.message.request-login"));
      history.push("");
      return;
    }

    UserApi.getCurrent().then(res => {
      setUser(res.data);
    });

    OrderApi.getOrders({ userId: userContext.id }).then((res) => {
      const orders = res.data.data;
      const orderDashboardItemsState = [...orderDashboardItems];
      orderDashboardItemsState[0].count = orders.length;
      orders.forEach(order => {
        switch (order.currentStatus) {
          case "pay_prepared":
            ++orderDashboardItemsState[1].count;
            order.currentStatus = intl.get("order.status.wait_pay");
            order.tabKey = orderDashboardItemsState[1].key;
            return;
          case "paid":
          case "pay_declared":
          case "order_declaring":
          case "order_declared":
            ++orderDashboardItemsState[2].count;
            order.currentStatus = intl.get("order.status.preparing_delivery");
            order.tabKey = orderDashboardItemsState[2].key;
            return;
          case "delivering":
            ++orderDashboardItemsState[3].count;
            order.currentStatus = intl.get("order.status.delivering");
            order.tabKey = orderDashboardItemsState[3].key;
            return;
          case "delivered":
            ++orderDashboardItemsState[4].count;
            order.currentStatus = intl.get("order.status.delivered");
            order.tabKey = orderDashboardItemsState[4].key;
            return;
          case "finished":
            ++orderDashboardItemsState[5].count;
            order.currentStatus = intl.get("order.status.finished");
            order.tabKey = orderDashboardItemsState[5].key;
            return;
          default:
            // ++orderDashboardItemsState[0].count;
            // order.currentStatus = intl.get("order.status.wait_paid");
            return;
        }
      });
      setOrderDashboardItems(orderDashboardItemsState);
      setOrderList(orders);
    });
  }, []);

  let filteredOrders = orderList;
  if (orderClicked !== "all") {
    filteredOrders = orderList.filter(order => order.tabKey === orderClicked)
  }
  return (
    <Container>
      <ul>
        <Subject>{intl.get("user.info")}</Subject>
        <Content>
          <p>{intl.get("user.name")}</p>
          <Input value={user.name ? user.name : ""} disabled />
        </Content>
        <Content>
          <p>{intl.get("user.email")}</p>
          <Input value={user.email ? user.email : ""} disabled />
        </Content>
        <Content>
          <p>{intl.get("user.phone")}</p>
          <Input value={user.phone ? user.phone : ""} disabled />
        </Content>
        <OrderDashboardTitle>{intl.get("order.detail")}</OrderDashboardTitle>
        <OrderDashboard>
          {orderDashboardItems.map((v, i) => (
            <OrderDashboardItem
              value={v.key}
              clicked={orderClicked}
              onClick={() => { onClickStatus(v.key); }}
              key={i}
            >
              <img src={v.image} alt="img" />
              <span className="statusName">{v.name}</span>
              <span className="statusCount">{v.count} {intl.get("common.label.count")}</span>
            </OrderDashboardItem>
          ))}
        </OrderDashboard>
      </ul>
      {filteredOrders && filteredOrders.map(order => (
        <OrderListItem key={order.id} order={order}/>
      ))}
      {(!filteredOrders || filteredOrders.length === 0) && (
        <div style={{textAlign: "center", marginTop: 100}}>
          <img src={NoDataImg}/>
        </div>
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  min-height: 1000px;
  margin-top: 80px;
`;

const Input = styled.input`
  width: 820px;
  height: 100%;
  box-sizing: border-box;
  font-size: 18px;
  border: none !important;
  background-color: white !important;
`;

const OrderDashboardTitle = styled.div`
  width: 100%;
  box-sizing: border-box;
  font-size: 24px;
  font-weight: 700;
  margin: 84px 0 21px;
`;

const OrderDashboard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 988px;
  height: 204px;
  background-color: ${gray100};
  border: 1px solid ${gray300};
  margin-bottom: 40px;
`;

const OrderDashboardItem = styled.div`
  display: flex;
  margin: 0 -1px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 204px;
  width: 202px;
  font-size: 20px;
  background-color: ${(props) =>
    props.clicked === props.value ? "white" : "unset"};
  border-collapse: collapse;
  border: 2px solid #00ff0000;
  border-color: ${(props) =>
    props.clicked === props.value
      ? `${mainColor2} ${mainColor2} #00ff0000 ${mainColor2}`
      : `#00ff0000 #00ff0000 ${mainColor2} #00ff0000`};
  border-bottom: ${(props) => !props.clicked && "2px solid #00ff0000"};
  cursor: pointer;
  user-select: none;
  img {
    width: 56px;
  }

  .statusName {
    font-size: 14px;
    color: ${gray500};
    font-weight: 400;
  }
  .statusCount {
    font-size: 18px;
    font-weight: 700;
    color: ${gray600};
  }
`;

export default Mypage;
