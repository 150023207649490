import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import ProductApi from "../components/apis/ProductApi";
import intl from "react-intl-universal";

import "../styles/pagination.css";
import MainBanner from "../assets/MainBanner.png";
import SelectArrow from "../assets/selectArrow.png";

import SearchContext from "../contexts/SearchContext";
import CategoryApi from "../components/apis/CategoryApi";
import ProductItem from "../components/productItem/ProductItem";
import ProductItemSkeleton from "../components/productItem/ProductItemSkeleton";
import { MemberContext } from "../auth/member.context";
import { Spin, Pagination, Empty } from 'antd';
import AppInfoContext from "../contexts/AppInfoContext";
import {
  CHANGE_MEMBER_CONTEXT_EVENT_TOPIC,
  EventBroadcaster,
  MEMBER_CONTEXT_AVAILABLE_EVENT_TOPIC
} from "../event/event.broadcaster";

function Home({ changeLogoClicked }) {
  const userContext = MemberContext.memberInformation;
  const { appInfo, systemSettings, appDesign } = useContext(AppInfoContext);

  const { searchValue, clicked } = useContext(SearchContext);
  const [page, setPage] = useState(1);
  const [productsList, setProductsList] = useState({});
  const [hideDetail, setHideDetail] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({
    first: "",
    second: "",
  });

  const getCategories = useCallback(() => {
    CategoryApi.getAllcategories().then((res) => {
      setCategories(res.data);
    });
  }, []);

  useEffect(() => {
    if (clicked === true) {
      getCategories();
      setSelectedCategory({
        first: "",
        second: "",
      });
      changeLogoClicked(false);
    }
  }, [clicked, changeLogoClicked, getCategories]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  useEffect(() => {
    setProductsList({ data: null });
    searchProductList(1);
    return;
  }, [searchValue, selectedCategory.first, selectedCategory.second]);

  useEffect( () => {
    // 브라우저 리로딩 등으로 인증 정보가 없어진 경우
    EventBroadcaster.on(MEMBER_CONTEXT_AVAILABLE_EVENT_TOPIC, (memberInformation) => {
      searchProductList(page);
    });
  }, []);

  const getSearchParams = (page) => {
    const params = {
      pageSize: 20,
      page: page,
      name: searchValue,
      categoryId: selectedCategory.first,
      subCategoryId: selectedCategory.second,
    };
    if (appInfo.isWholesaleApp) {
      params["wholesaleProduct"] = true
    }
    return params;
  };

  const handlePageChange = (page) => {
    setPage(page);
    searchProductList(page);
  };

  const searchProductList = (page) => {
    ProductApi.getProducts(getSearchParams(page))
      .then((res) => {
        const products = res.data.data;
        setHideDetail(products.length > 0 && products[0].id === 0);
        setProductsList(res.data);
      }).catch();
  }

  const onChangeFirstCategory = useCallback((e) => {
    setSelectedCategory({ first: e.target.value, second: "" });
  }, []);

  const onChangeSecondCategory = useCallback(
    (e) => {
      setSelectedCategory({ ...selectedCategory, second: e.target.value });
    },
    [selectedCategory]
  );

  const bannerImage = appDesign?.themeSetting?.webmall?.banner ?
    appDesign?.themeSetting?.webmall?.banner : MainBanner;

  const MemorizedBanner = React.memo(() => <Banner image={bannerImage}/>);

  return (
    <Spin spinning={hideDetail}
          indicator={(<div></div>)}
          size="large"
          tip={
            <span style={{fontSize: 26, color: "#3956ed", textShadow: "none", fontVariant: "none"}}>
              {intl.get("common.message.login")}
            </span>}>
      <Container>
        <MemorizedBanner />
        <CategoryWrapper>
          <Category
            onChange={onChangeFirstCategory}
            value={selectedCategory.first}
          >
            <option value="">{intl.get("category")}</option>
            {categories.map((v, i) => (
              <option key={i} value={v.id}>
                {v.name}
              </option>
            ))}
          </Category>
          {/*<Category*/}
          {/*  onChange={onChangeSecondCategory}*/}
          {/*  value={selectedCategory.second}*/}
          {/*>*/}
          {/*  <option value="">{intl.get("category.second")}</option>*/}
          {/*  {categories*/}
          {/*    .filter((v) => v.id === Number(selectedCategory.first))[0]*/}
          {/*    ?.subCategories.map((v, i) => (*/}
          {/*      <option key={i} value={v.id}>*/}
          {/*        {v.name}*/}
          {/*      </option>*/}
          {/*    ))}*/}
          {/*</Category>*/}
        </CategoryWrapper>
        <ProductItemWrapper>
          {(!productsList.data || productsList.data.length === 0) && (
            <div style={{padding: 100, width: "100%"}}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/></div>
          )}
          {productsList.data ? (
            productsList.data.map((product, i) => {
              if (hideDetail) {
                return (
                  <ProductItemSkeleton key={i}/>
                )
              }
              return (
                <ProductItem
                  key={i}
                  name={product.name}
                  listPrice={product.listPrice}
                  salesPrice={product.salesPrice}
                  images={product.images}
                  itemId={product.id}
                />
              );
            })
          ) : (
            <>
              <ProductItemSkeleton />
              <ProductItemSkeleton />
              <ProductItemSkeleton />
              <ProductItemSkeleton />
            </>
          )}
        </ProductItemWrapper>
        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
          <Pagination defaultCurrent={1}
                      current={page}
                      pageSize={productsList.pageSize || 20}
                      total={productsList.count || 0}
                      onChange={handlePageChange}
          />
        </div>
      </Container>
    </Spin>
  );
}

const Container = styled.div`
  width: 988px;
`;

const Banner = styled.div`
  width: 100%;
  height: 300px;
  background-color: white;
  font-size: 50px;
  text-align: center;
  margin: 68px 0 32px;
  background-image: url(${(props) => props.image});
  background-size: 100% 100%;
  background-repeat: no-repeat;
`;

const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 32px;
`;

const Category = styled.select`
  width: 242px;
  height: 44px;
  border: 1px solid #9e9e9e;
  border-radius: 2px;
  margin-right: 24px;
  font-weight: 700;
  font-size: 16px;
  padding: 10px 16px;
  box-sizing: border-box;
  appearance: none;
  background: url(${SelectArrow}) no-repeat right 14.75px center;

  :focus {
    outline: none;
  }
`;

const ProductItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export default Home;
