import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import intl from "react-intl-universal";
import Util from "../../utils/Util";
import {message, Modal} from "antd";

import { error, gray400, gray500, mainColor2 } from "../../styles/fontColors";

import EssentialSignImg from "../../assets/essentialSign.png";
import OrderApi from "../../components/apis/OrderApi";
import AppInfoContext from "../../contexts/AppInfoContext";
import MypagePrice from "../../components/priceList/MypagePrice";
import { MemberContext } from "../../auth/member.context";
import OrderSkuPrice from "./OrderSkuPrice";
import DaumPostcode from 'react-daum-postcode';
import AddressModal from "../../components/address/AddressModal";

function PrepareOrder({ handleModal }) {
  const { appInfo } = useContext(AppInfoContext);
  const user = MemberContext.memberInformation;

  const history = useHistory();
  const location = useLocation();
  const { selectedSkus, itemName } = location.state;
  const [paymentInfo, setPaymentInfo] = useState({});
  const [visibleAddress, setVisibleAddress] = useState(false);
  const [visibleKoreaAddress, setVisibleKoreaAddress] = useState(false);

  useEffect(() => {
    let paymentMethod = "wechatpay"
    let paymentMethodText = intl.get("order.wechatpay");
    let paymentDetail = "";
    if (appInfo.isWholesaleApp) {
      paymentMethod = appInfo.wholesaleSetting?.paymentMethod;
      paymentMethodText = intl.get("order." + paymentMethod);
      if (appInfo.wholesaleSetting?.paymentMethod === "account_transfer") {
        paymentDetail = appInfo.wholesaleSetting.paymentAccount;
      }
    }
    setPaymentInfo({
      paymentMethod: paymentMethod,
      paymentMethodText: paymentMethodText,
      paymentDetail: paymentDetail,
    })
  }, []);

  const prepareOrderParam = {
    couponIds: [],
    skus: selectedSkus.map((v) => ({
      cartId: 0,
      productId: v.productId,
      skuId: v.id,
      quantity: v.quantity,
    })),
  };

  const [order, setOrder] = useState({
    orderSkus: [...selectedSkus],
    shippingAddress: {},
    settlement: {},
  });

  useEffect(() => {
    OrderApi.prepareOrder(prepareOrderParam)
      .then((res) => {
        setOrder(res.data);
      })
      .catch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeOrder = useCallback((key, e) => {
    const orderState = { ...order };

    if (key === "recipient" || key === "phone" || key === "userComments") {
      if (!orderState.shippingAddress) {
        orderState.shippingAddress = {};
      }
      orderState.shippingAddress[key] = e.target.value;
    } else {
      orderState[key] = e.target.value;
    }
    setOrder(orderState);
  }, [order]);

  const onAddressSelected = useCallback(
    (provinceName, cityName, countyName, county, detail) => {
      const countryCode = county === "990101" ? "82" : "86";
      const orderState = { ...order };
      const shippingAddress = {
        addressProvince: provinceName,
        addressCity: cityName,
        addressDistrict: countyName,
        addressCode: county,
        addressDetail: detail,
        countryCode: countryCode,
      };
      if (orderState.shippingAddress) {
        Object.assign(orderState.shippingAddress, shippingAddress);
      } else {
        orderState.shippingAddress = shippingAddress;
      }
      setOrder(orderState);
      setVisibleAddress(false);
    },
    [order]
  );

  const onClickPurchaseBtn = async () => {
    if (!Util.isLoginUser()) {
      alert(intl.get("common.message.request-login"));
      return;
    }

    if (!paymentInfo.paymentMethod) {
      alert(intl.get("common.message.contactAdmin"));
    }

    for (let key in order.shippingAddress) {
      if (key === "countryCode" || key === "userComments") continue;
      if (order.shippingAddress[key] === "") {
        alert(intl.get("order.delivery.input"));
        return;
      }
    }

    order.clientApp = "native";
    OrderApi.createOrder(order).then(res => {
      if (res.data?.order?.id) {
        const createdOrder = res.data.order;
        message.info(intl.get("order.created"));
        if (paymentInfo.paymentMethod === "wechatpay") {
          if (!res.data.preparePayment) {
            alert(intl.get("order.payment.error"));
            return;
          }
          const preparePayment = JSON.parse(res.data.preparePayment);
          history.push({
            pathname: `/order/${createdOrder.orderNo}/pay`,
            state: {
              wxPayLink: preparePayment.codeUrl, //weixin://wxpay/bizpayurl?pr=hK95Wdtzz
              paymentAmount: order.paymentAmount,
              paymentCurrency: order.paymentCurrency,
              productCurreny: order.productCurrency,
              orderId: createdOrder.id,
              orderDate: createdOrder.orderDate,
            },
          });
        } else {
          history.push({
            pathname: "/success",
            state: {
              orderId: createdOrder.id,
              orderNo: createdOrder.orderNo,
              orderDate: createdOrder.orderDate,
            },
          });
        }
      }
    }).catch((err) => {
      console.error("createOrder.err: ", err);
      alert(intl.get("common.message.contactAdmin"));
    });
  };

  const getOrderAddress = () => {
    if (Object.keys(order.shippingAddress).length === 0) {
      return "";
    }
    if (order.shippingAddress.countryCode === "82") {
      return order.shippingAddress.addressDetail;
    } else {
      return order.shippingAddress.addressProvince +
        "  " +
        order.shippingAddress.addressCity +
        "  " +
        order.shippingAddress.addressDistrict +
        "  " +
        order.shippingAddress.addressCode +
        "  " +
        order.shippingAddress.addressDetail;
    }
  }

  const onClickInputAddressBtn = useCallback(() => {
    setVisibleAddress(true);
  }, [visibleAddress]);

  const postCodeStyle = {
    display: 'block',
    position: 'relative',
    top: '0%',
    width: '640px',
    height: '400px',
    padding: '7px',
  };

  const onCompleteKoreaAddress = useCallback((data) => {
    setVisibleKoreaAddress(false);
      const orderState = { ...order };
      const shippingAddress = {
        addressProvince: data.sido,
        addressCity: data.sigungu,
        addressDistrict: data.roadname,
        addressCode: data.zonecode,
        addressDetail: data.address,
        countryCode: "82",
      };
      if (orderState.shippingAddress) {
        Object.assign(orderState.shippingAddress, shippingAddress);
      } else {
        orderState.shippingAddress = shippingAddress;
      }
      setOrder(orderState);
    },
    [order]
  );

  return (
    <Container>
      <ul>
        <Subject>{intl.get("order.list.recipientInfo")}</Subject>
        <ul>
          <Content>
            <img className="essentialSign" src={EssentialSignImg} alt="essentialSign" />
            <p>{intl.get("order.recipient")}</p>
            <input onChange={(e) => { onChangeOrder("recipient", e); }} />
          </Content>
          <Content>
            <img className="essentialSign" src={EssentialSignImg} alt="essentialSign" />
            <p>{intl.get("order.phone")}</p>
            <input onChange={(e) => { onChangeOrder("phone", e); }} />
          </Content>
          {!appInfo.isWholesaleApp && (
            <>
              <Content>
                <img className="essentialSign" src={EssentialSignImg} alt="essentialSign" />
                <p>{intl.get("order.idCardNo")}</p>
                <input onChange={(e) => { onChangeOrder("idCardNo", e); }} />
              </Content>
              <Content>
                <img className="essentialSign" src={EssentialSignImg} alt="essentialSign" />
                <p>{intl.get("order.realName")}</p>
                <input onChange={(e) => { onChangeOrder("userName", e); }} />
              </Content>
            </>
          )}
        </ul>
        <Subject>{intl.get("order.deliveryInfo")}</Subject>
        <ul>
          <Content>
            <p>{intl.get("order.address")}</p>
            <div>
              <input className="addressInput" value={getOrderAddress()} disabled />
              <button onClick={onClickInputAddressBtn}>
                {intl.get("order.addressInput")}
              </button>
            </div>
          </Content>
          <Content>
            <p>{intl.get("order.userComment")}</p>
            <input onChange={(e) => { onChangeOrder("userComments", e); }} />
          </Content>
        </ul>
        <Subject>{intl.get("order.list")}</Subject>
        {selectedSkus.map((sku, i) => {
          return (
            <SkusItem key={i}>
              {sku.skuImage && (
                <img src={sku.skuImage} alt="skuImage" />
              )}
              <SkusContent>
                <SkusSubject>
                  <span>{itemName}</span>
                  <span className="skusOption">{sku.optionText}</span>
                </SkusSubject>
                <OrderSkuPrice salesPrice={sku.salesPrice} quantity={sku.quantity}/>
              </SkusContent>
            </SkusItem>
          );
        })}
        {user.id && (
          <>
            <Subject>{intl.get("order.list.paymentInfo")}</Subject>
            <ul>
              <PriceContent>
                <p style={{ marginTop: "24px", marginBottom: "24px" }}>{intl.get("order.offerListPrice")}</p>
                <MypagePrice amount={order?.orderAmount} fontSize="20px" />
              </PriceContent>
              <PriceContent>
                <p style={{ marginBottom: "24px" }}>{intl.get("order.deliveryFee")}</p>
                <MypagePrice amount={order?.deliveryFee} fontSize="20px" />
              </PriceContent>
              <PriceContent>
                <p style={{ marginBottom: "0" }}>{intl.get("order.paymentAmountUser")}</p>
                <MypagePrice amount={order?.paymentAmount} fontSize="20px" />
              </PriceContent>
              <PaymentPrice>
                <p>{intl.get("order.paymentAmount")}</p>
                <MypagePrice amount={order?.paymentAmount} fontSize="30px" color={error}/>
              </PaymentPrice>
            </ul>
            <Subject>{intl.get("order.means-of-payment")}</Subject>
            <PaymentOptions>
              <div>{paymentInfo.paymentMethodText}</div>
              {paymentInfo.paymentMethod === "account_transfer" && (
                <>
                  <div>{paymentInfo.paymentDetail}</div>
                  <div style={{marginTop: 10, fontSize: 16, color: "gray"}}>
                    <div>{intl.get("order.message.krw1")}</div>
                    {appInfo.qrCode && (
                      <div>
                        <div>{intl.get("order.message.krw2")}</div>
                        <img src={appInfo.qrCode} style={{width: 200, height: 200}}/>
                      </div>
                    )}
                  </div>
                </>
              )}
            </PaymentOptions>
          </>
        )}
        <ButtonWrapper>
          <PurchaseBtn onClick={onClickPurchaseBtn}>
            {paymentInfo.paymentMethod === "wechat" ? intl.get("common.button.pay") : intl.get("common.button.buy")}
          </PurchaseBtn>
        </ButtonWrapper>
      </ul>
      {visibleKoreaAddress && (
        <Modal visible={visibleKoreaAddress}
               width={700}
               cancelButtonProps={{style: {display: 'none'}}}
               onCancel={() => setVisibleKoreaAddress(false)}>
          <DaumPostcode style={postCodeStyle} autoClose onComplete={onCompleteKoreaAddress } />
        </Modal>
      )}
      {visibleAddress && (
        <AddressModal
          addressCode={appInfo?.isWholesaleApp ? "990101" : ""}
          disableSelection={appInfo?.isWholesaleApp}
          onCancel={() => setVisibleAddress(false)}
          onOk={onAddressSelected}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 72px 0 80px;
`;

const Subject = styled.li`
  width: 100%;
  box-sizing: border-box;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  padding-bottom: 20px;
  box-sizing: border-box;
  border-bottom: 2px solid #212121;
  margin-top: 80px;

  :first-child {
    margin-top: 0;
  }
`;

const Content = styled.li`
  display: flex;
  max-height: 44px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 20px;

  div {
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 820px;
  }

  p {
    font-size: 16px;
    font-weight: 700;
    margin-left: 8px;
    margin: 0 0 0 8px;
  }

  .addressInput {
    width: 652px;
  }

  input {
    width: 820px;
    height: 44px;
    box-sizing: border-box;
    font-size: 20px;
    border: 1px solid #e0e0e0;
    padding: 10px 16px;
  }

  button {
    height: 44px;
    width: 148px;
    background-color: ${gray500};
    border-radius: 2px;
    border: none;
    font-size: 16px;
    font-weight: 400;
    color: white;
    cursor: pointer;
  }

  .essentialSign {
    position: absolute;
    width: 6px;
    top: 15px;
    left: 0px;
  }
`;

const PriceContent = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-right: 10px;

  p {
    font-size: 16px;
    font-weight: 400;
    margin-left: 8px;
  }
  :nth-child(3) {
    padding-top: 20px;
    padding-bottom: 26px;
    border-top: 1px solid #e0e0e0;
  }
`;

const PaymentPrice = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 24px;
  border-top: 2px solid #212121;
  font-size: 20px;
  font-weight: 700;

  p {
    margin: 0 10px;
    vertical-align: middle;
  }

  .orderPagePrice {
    color: ${error};
  }

  div {
    line-height: 30px;
    margin-right: 5px;
  }
`;

const SkusItem = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 148px;
  width: 100%;
  margin-top: 20px;
  :first-child {
    margin-top: 24px;
  }
  img {
    height: 100%;
  }
`;

const SkusContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 819px;
  height: 90%;
`;

const SkusSubject = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 700;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .skusOption {
    color: ${gray400};
    font-size: 14px;
    font-weight: 400;
  }
`;

const PaymentOptions = styled.div`
  width: 98%;
  font-size: 18px;
  margin-top: 20px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 104px;
`;

const PurchaseBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 276px;
  height: 72px;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${mainColor2};
  color: white;
  font-size: 20px;
  font-weight: 700;
`;

export default PrepareOrder;
