import React, { useCallback, useReducer, useState, useEffect } from "react";
import PageRouter from "../../pages/router/PageRouter";
import CommonModal from "../modal/CommonModal";

import "./AppLayout.css";
import { gray500 } from "../../styles/fontColors";

import Header from "./Header";
import Footer from "./Footer";
import SearchContext from "../../contexts/SearchContext";
import { initialState, reducer } from "../../contexts/SearchContext";

function AppLayout() {
  const [isOpen, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleClick = (
    children,
    skip,
    order,
    selectCountry,
    color = gray500,
    width,
    height,
    token
  ) => {
    setModalContent({ children, order, selectCountry, color, width, height, token });
    if (skip) {
      return;
    }
    setOpen((prev) => !prev);
  };

  const changeSearchValue = useCallback((value) => {
    dispatch({
      type: "CHANGE_VALUE",
      searchValue: value,
    });
  }, []);

  const changeLogoClicked = useCallback((clicked) => {
    dispatch({
      type: "CHANGE_CLICKED",
      clicked,
    });
  }, []);

  return (
    <SearchContext.Provider value={state}>
      <CommonModal
        isOpen={isOpen}
        modalContent={modalContent.children}
        handleModal={handleClick}
        order={modalContent.order}
        selectCountry={modalContent.selectCountry}
        color={modalContent.color}
        width={modalContent.width}
        height={modalContent.height}
        token={modalContent.token}
      />
      <div>
        <Header
          handleModal={handleClick}
          changeSearchValue={changeSearchValue}
          changeLogoClicked={changeLogoClicked}
        />
        <PageRouter
          handleModal={handleClick}
          changeLogoClicked={changeLogoClicked}
        />
      </div>
      <Footer />
    </SearchContext.Provider>
  );
}

export default AppLayout;
